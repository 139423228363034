<template>
    <div class="input_light-search">
        <div class="input_light-search__search-icon">
            <MagnifyIcon fill-color="#606060"></MagnifyIcon>
        </div>
        <InputLight :placeholder="placeholder" :type="type" :value="value" @input="input" class="input_light_search" />
    </div>
</template>
<script>
import InputLight from './InputLight';
import MagnifyIcon from 'vue-material-design-icons/Magnify';

export default {
    components: {
        InputLight,
        MagnifyIcon,
    },
    props: {
        type: {
            type: String,
            default: 'text',
        },
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
    },
    methods: {
        input(value) {
            this.$emit('input', value);
        },
    },
};
</script>
<style lang="scss">
.input {
    &_light-search {
        display: flex;
        width: 100%;
        position: relative;

        &__search-icon {
            position: absolute;
            width: 24px;
            height: 24px;
            top: 0;
            left: 0;
            margin: 2px 0.25rem 0 0.25rem;
        }
    }

    &_light {
        &_search {
            padding: 0 0.5rem 0 calc(24px + 0.5rem);
        }
    }
}
</style>
