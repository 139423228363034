<template>
    <div class="request-store request-store_loader">
        <InfiniteLoading
            @infinite="infiniteHandlerTop"
            direction="top"
            v-if="currentBottomPage > currentBottomPage + differencePage"
        >
            <VSpinner slot="spinner"></VSpinner>
        </InfiniteLoading>
        <ul class="request-store__list">
            <transition-group name="animation-fadedown">
                <template v-for="request in requests">
                    <li :key="request.id" class="request-store__item">
                        <slot :request="request">{{ request.id }}</slot>
                    </li>
                </template>
            </transition-group>
        </ul>
        <InfiniteLoading @infinite="infiniteHandlerBottom" :identifier="infiniteId" v-if="infiniteId > 0">
            <VSpinner slot="spinner"></VSpinner>
        </InfiniteLoading>
    </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';

import VSpinner from '@/views/components/VProgress/VSpinner';
import InfiniteLoading from 'vue-infinite-loading';
import http from '../../../js/http';

@Component({
    components: {
        VSpinner,
        InfiniteLoading,
    },
})
class RequestStoreLoader extends Vue {
    @Prop({ type: Number, default: 1 }) page;
    @Prop({ type: Number, default: 10 }) perPage;
    @Prop({ type: Number, default: 30 }) maxRequestsDisplay;
    @Prop({ type: String, default: '' }) endpoint;

    infiniteId = 0;

    filter = {};
    requests = [];
    totalPages = 1;
    currentTopPage = 1;
    currentBottomPage = 1;
    isLoadedInfiniteHandlerTop = false;
    isLoadedInfiniteHandlerBottom = false;

    @Emit('loaded')
    loaded() {
        return this.getEventData();
    }

    infiniteHandlerTop($state, timeout = true) {
        const data = {
            page: this.currentTopPage,
            perPage: this.perPage,
            ...this.filter,
        };

        if (this.isLoadedInfiniteHandlerBottom || timeout) {
            return setTimeout(this.infiniteHandlerTop, 250, $state, false);
        }

        this.isLoadedInfiniteHandlerTop = true;

        http.post(this.endpoint, data).then(({ data: response }) => {
            this.totalPages = response.last_page;
            this.currentTopPage = this.currentTopPage - 1;

            this.requests.unshift(...response.data);

            if (this.requests.length > this.maxRequestsDisplay) {
                this.$nextTick(() => {
                    this.requests.splice(-this.perPage, this.perPage);
                    this.currentBottomPage = this.currentTopPage + this.differencePage;
                });
            }

            this.isLoadedInfiniteHandlerTop = false;

            this.$nextTick(() => {
                this.loaded();

                response.current_page === 1 ? $state.complete() : $state.loaded();
            });
        });
    }

    infiniteHandlerBottom($state, timeout = true) {
        const data = {
            page: this.currentBottomPage,
            perPage: this.perPage,
            ...this.filter,
        };

        if (this.isLoadedInfiniteHandlerTop || timeout) {
            return setTimeout(this.infiniteHandlerBottom, 250, $state, false);
        }

        this.isLoadedInfiniteHandlerBottom = true;

        http.post(this.endpoint, data).then(({ data: response }) => {
            this.totalPages = response.last_page;
            this.currentBottomPage = this.currentBottomPage + 1;

            this.requests.push(...response.data);

            if (this.requests.length > this.maxRequestsDisplay) {
                this.$nextTick(() => {
                    this.requests.splice(0, this.perPage);
                    this.currentTopPage = this.currentBottomPage - this.differencePage;
                });
            }

            this.isLoadedInfiniteHandlerBottom = false;

            this.$nextTick(() => {
                this.loaded();

                response.current_page === response.last_page ? $state.complete() : $state.loaded();
            });
        });
    }

    getPrevEndpoint() {
        const endpoint = new URL(this.endpoint, window.origin);

        endpoint.searchParams.set('page', this.currentTopPage);
        endpoint.searchParams.set('per_page', this.perPage);

        return endpoint;
    }

    getNextEndpoint() {
        const endpoint = new URL(this.endpoint, window.origin);

        endpoint.searchParams.set('page', this.currentBottomPage);
        endpoint.searchParams.set('per_page', this.perPage);

        return endpoint;
    }

    getEventData() {
        return {
            reqeusts: this.reqeusts,
            totalPages: this.totalPages,
            currentTopPage: this.currentTopPage,
            currentBottomPage: this.currentBottomPage,
        };
    }

    onFilterUpdate(filter) {
        this.requests = [];

        this.filter = filter;
        this.currentTopPage = 1;
        this.currentBottomPage = 2;

        this.infiniteId = this.infiniteId + 1;
        // this.$nextTick(() => {
        //     this.infiniteId = this.infiniteId + 1;
        // });
    }

    get differencePage() {
        return this.maxRequestsDisplay / this.perPage + 1;
    }

    created() {
        this.$bus.on('RequestStoreFilter:Update', this.onFilterUpdate);
    }

    beforeDestroy() {
        this.$bus.off('RequestStoreFilter:Update', this.onFilterUpdate);
    }
}

export default RequestStoreLoader;
</script>
<style lang="scss">
.request-store {
    &__list {
        padding: 0;
        margin: 20px 0;
        list-style: none;
    }

    &__item {
        width: 100%;
    }
}
</style>
