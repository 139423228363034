<template>
    <div class="resource-item">
        <span class="resource-item__row text">{{ item.title }}</span>
        <small class="resource-item__row text-sm">{{ item.subtitle ? item.subtitle : defaultSubtitle }}</small>
    </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import PencilOutlineIcon from 'vue-material-design-icons/PencilOutline';
import DeleteForeverOutlineIcon from 'vue-material-design-icons/DeleteForeverOutline';

@Component({
    components: { PencilOutlineIcon, DeleteForeverOutlineIcon },
})
class ResourceItem extends Vue {
    @Prop({ type: Object, default: {} }) item;
    @Prop({ type: String, default: '' }) defaultSubtitle;
}

export default ResourceItem;
</script>
<style lang="scss">
.resource-item {
    display: flex;
    padding: 3px 0;
    margin-top: -1px;
    margin-left: 15px;
    margin-right: 15px;
    position: relative;
    flex-direction: column;
    border-top: 1px solid #e8ecee;
    border-bottom: 1px solid #e8ecee;

    &:hover {
        z-index: 1000;
        margin-left: 0;
        margin-right: 0;
        padding: 3px 15px;
        border-top: 1px solid #a8acae;
        border-bottom: 1px solid #a8acae;
    }

    &:hover > &__actions {
        display: flex;
    }

    &__row {
        display: block;
        width: 100%;
        padding: 0 15px;
    }
}
</style>
