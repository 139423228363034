<template>
    <VGridPageColumn sm-4 xs-12>
        <VGridRow class="grid-row_padding-bottom">
            <VGridCol md-2 xs-3>
                <RouterLink
                    to="/request/inbox"
                    class="button button_no-shadow button_center button_without-padding button_padding-x request-create-store__button-back"
                >
                    <ChevronLeftIcon class="button__title" :size="24" fill-color="#606060"> </ChevronLeftIcon>

                    <span> Назад</span>
                </RouterLink>
            </VGridCol>
            <VGridCol md-10 xs-9>
                <BaseToolsSearch v-model="searchString" placeholder="Поиск" />
            </VGridCol>
        </VGridRow>
        <VGridScroll class="v-scroll_hidden">
            <template v-if="!isLoadingData">
                <VGridRow class="grid-row_padding-y">
                    <span class="text-lg">Категория:</span>
                </VGridRow>
                <VGridRow class="grid-row_without-padding grid-row_padding-y">
                    <VGridCol role="list">
                        <transition-group name="animation-fadedown">
                            <template v-for="category in filteredCategories">
                                <RouterLink
                                    :key="category.id"
                                    :to="getServiceUrl(category)"
                                    class="grid-flex grid-flex_column"
                                >
                                    <ResourceItem :item="category"></ResourceItem>
                                </RouterLink>
                            </template>
                        </transition-group>
                    </VGridCol>
                </VGridRow>
            </template>

            <VGridRow class="grid-row_fluid grid-row_center" v-else>
                <VSpinner></VSpinner>
            </VGridRow>
        </VGridScroll>
    </VGridPageColumn>
</template>
<script>
import Vue from 'vue';
import http from '../../../js/http';
import Component from 'vue-class-component';

import { ResourceItem, ResourceLayer, CategoryForm } from './components';
import { VGridRow, VGridCol, VGridScroll, VActiveForm, VGridPageColumn } from '../../components';

import VButton from '@/views/components/VButton/VButton.vue';
import VSpinner from '@/views/components/VProgress/VSpinner.vue';
import BaseToolsSearch from '@/views/components/tools/BaseToolsSearch';

import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft';

@Component({
    components: {
        VSpinner,
        VGridRow,
        VGridCol,
        VGridScroll,
        VActiveForm,
        VGridPageColumn,

        ChevronLeftIcon,

        ResourceItem,
        ResourceLayer,
        CategoryForm,

        VButton,
        BaseToolsSearch,
    },
})
class RequestCreateStore extends Vue {
    category = {};
    categories = [];
    searchString = '';
    isLoadingData = false;

    getServiceUrl(item) {
        const categoryId = item.id;

        return `/request/categories/${categoryId}/services`;
    }

    get filteredCategories() {
        if (this.searchString === '') return this.categories;

        const searchString = this.searchString.toLowerCase();

        return this.categories.filter(
            (category) =>
                category.title.toLowerCase().indexOf(searchString) !== -1 ||
                category.subtitle.toLowerCase().indexOf(searchString) !== -1,
        );
    }

    created() {
        this.isLoadingData = true;

        http.get('/categories').then(({ data: categories }) => {
            this.categories = categories;
            this.isLoadingData = false;
        });
    }
}

export default RequestCreateStore;
</script>
<style lang="scss">
.request-create-store {
    &__button-back {
        height: 28px;
        line-height: 28px;
        margin-right: 10px;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0);

        &:hover {
            background-color: rgba(44, 44, 44, 0.12);
        }
    }
}
</style>
