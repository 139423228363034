<template>
    <RequestRow>
        <RequestContent>
            <RequestCell :class="['request__cell_id', 'request__cell_border', 'request__cell_without-left-border']">
                <RequestContent>#</RequestContent>

                <RequestOverlay
                    :class="[
                        'request__overlay_hover',
                        'request__overlay_first-cell',
                        'request__overlay_without-left-border',
                    ]"
                ></RequestOverlay>
            </RequestCell>

            <RequestCell :class="['request__cell_border', 'request__cell_author']">
                <RequestContent :class="['request__content_hidden-ellipsis']">Автор</RequestContent>

                <RequestOverlay :class="['request__overlay_hover']"></RequestOverlay>
            </RequestCell>

            <RequestCell :class="['request__cell_executor', 'request__cell_border']">
                <RequestContent :class="['request__content_hidden-ellipsis']">Исполнитель</RequestContent>

                <RequestOverlay :class="['request__overlay_hover']"></RequestOverlay>
            </RequestCell>

            <RequestCell :class="['request__cell_executor', 'request__cell_border']">
                <RequestContent :class="['request__content_hidden-ellipsis']">Срок</RequestContent>

                <RequestOverlay :class="['request__overlay_hover']"></RequestOverlay>
            </RequestCell>

            <RequestCell
                :class="['request__cell_status', 'request__cell_border', 'request__cell_without-right-border']"
            >
                <RequestContent :class="['request__content_hidden-ellipsis']">Статус</RequestContent>

                <RequestOverlay
                    :class="[
                        'request__overlay_hover',
                        'request__overlay_last-cell',
                        'request__overlay_without-right-border',
                    ]"
                ></RequestOverlay>
            </RequestCell>
        </RequestContent>
    </RequestRow>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import RequestRow from './RequestRow';
import RequestCell from './RequestCell';
import RequestContent from './RequestContent';
import RequestOverlay from './RequestOverlay';

@Component({
    components: {
        RequestRow,
        RequestCell,
        RequestContent,
        RequestOverlay,
    },
})
class RequestTableHeader extends Vue {}

export default RequestTableHeader;
</script>
