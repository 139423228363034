<template>
    <div class="v-form__field-wrapper">
        <slot></slot>
        <div class="errors" v-if="result">
            <span :key="i" class="v-form__field-wrapper-error" v-for="(error, i) in result">{{ error }}</span>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
class VFormFieldWrapper extends Vue {
    @Prop(String) name;
    @Prop(Array) result;
    @Prop([Object, String, Number, Boolean, Array]) model;
}

export default VFormFieldWrapper;
</script>
<style lang="scss">
.v-form__field-wrapper {
    padding: 5px 0;
    font: 400 0.9rem Roboto;

    &-error {
        color: #f00;
    }
}
</style>
