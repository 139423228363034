import NavMenu from '../views/pages/Shared/NavMenu.vue';
import { RequestStore } from '../views/pages/Request';
import RequestView from '../views/pages/Request/RequestView.vue';
import oidc from '../vue-oidc-client';

export default [
    //aliases for legacy routing
    {
        path: '/',
        redirect: '/request/inbox',
        meta: {
            authName: oidc.authName,
        },
    },
    {
        path: '/feed/:method?/:id?',
        redirect: '/request/:method?/:id?',
        meta: {
            authName: oidc.authName,
        },
    },

    {
        name: 'request',
        path: '/request/:method(inbox|outbox|archive|store|view|\\d+)/:id?',
        components: {
            NavMenu,
            RequestStore,
            RequestView,
        },
        meta: {
            authName: oidc.authName,
        },
    },
];
