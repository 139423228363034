<template>
    <div class="active-form__item">
        <label for="" v-if="label">
            {{ label }}
        </label>
        <slot></slot>

        <div :key="i" class="active-form__item-error" v-for="(message, i) in messages">
            <slot :message="message" name="message">
                <span class="active-form__item-message active-form__item-message_danger">{{ message }}</span>
            </slot>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
class VActiveFormItem extends Vue {
    @Prop() type;
    @Prop() value;
    @Prop({ type: String, default: '', required: true }) name;
    @Prop({ type: String, default: '', required: true }) form;
    @Prop({ type: String, default: '' }) label;

    field = {};
    messages = [];

    getValue() {
        return this.type ? this.type(this.value) : this.value;
    }

    onValidation(messages) {
        this.messages = messages;
    }

    created() {
        this.field = this.$_registerFormField(this.form, this.name, this);
    }

    beforeDestroy() {
        this.$_removeFormField(this.form, this.field.name);
    }
}

export default VActiveFormItem;
</script>
<style lang="scss">
.active-form {
    &__item {
        display: flex;
        width: 100%;
        padding: 0 15px;
        flex-direction: column;
    }

    &__item-message {
        display: block;

        &_danger {
            color: var(--color-red);
        }
    }
}
</style>
