<template>
    <VueSelect
        :options="options"
        class="v-select-light"
        placeholder="Все"
        :reduce="(option) => option.value"
        v-model="value"
    ></VueSelect>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import InputCheckbox from '@/views/components/VCheckbox/InputCheckbox.vue';

@Component({
    components: {
        InputCheckbox,
    },
})
class FilterExpired extends Vue {
    value = 1;
    options = [
        { label: 'Все', value: 1 },
        { label: 'Просроченные', value: 2 },
        { label: 'Не просроченные', value: 3 },
    ];

    getValue() {
        return this.value ? this.value : undefined;
    }

    reset() {
        this.value = 1;
    }
}

export default FilterExpired;
</script>
