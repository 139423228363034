<template>
    <!-- If user's agent is Internet Explorer, we show the stub for IE -->
    <IEpage v-if="isIE"></IEpage>

    <VAppLayout v-else-if="isVuexBooted">
        <VGridPage></VGridPage>

        <VImagePopup></VImagePopup>

        <VErrorModal></VErrorModal>
        <VRequestPauseModal></VRequestPauseModal>
        <VRequestChangeServiceModal></VRequestChangeServiceModal>
        <VRequestChangeExecutorModal></VRequestChangeExecutorModal>
        <VRequestSendToBitrix24Modal></VRequestSendToBitrix24Modal>
    </VAppLayout>

    <!-- While vuex is loading, we show the stub -->
    <div class="loading-app loading-app--center" v-else>
        <VSpinner></VSpinner>

        <!-- Needs for the auth routes -->
        <template v-if="isSignInPage">
            <RouterView></RouterView>
        </template>
    </div>
</template>
<script>
// Components
import VImagePopup from './views/components/VImagePopup/VImagePopup.vue';
import VErrorModal from './views/modals/VErrorModal.vue';
import VRequestPauseModal from './views/modals/VRequestPauseModal.vue';
import VRequestChangeServiceModal from './views/modals/VRequestChangeServiceModal.vue';
import VRequestChangeExecutorModal from './views/modals/VRequestChangeExecutorModal.vue';
import VRequestSendToBitrix24Modal from './views/modals/VRequestSendToBitrix24Modal.vue';

import VSpinner from '@/views/components/VProgress/VSpinner.vue';
import VAppLayout from './views/components/VApp';
import VGridPage from './views/components/VGrid/VGridPage';
import { isVuexBooted } from './store';

import IEpage from './views/pages/ErrorPage/IE';

// Other
import Vue from 'vue';
import oidc from './vue-oidc-client';
import Component from 'vue-class-component';
import './scss/style.scss';

@Component({
    components: {
        IEpage,
        VSpinner,
        VGridPage,
        VAppLayout,
        VImagePopup,

        VErrorModal,
        VRequestPauseModal,
        VRequestChangeServiceModal,
        VRequestChangeExecutorModal,
        VRequestSendToBitrix24Modal,
    },
})
class App extends Vue {
    isVuexBooted = false;

    get isIE() {
        const userAgent = window.navigator.userAgent.toLowerCase();

        return /trident/gi.test(userAgent) || /msie/gi.test(userAgent);
    }

    get isSignInPage() {
        return this.$route.name === 'signinwin-sso';
    }

    vuexBootedHandler() {
        this.isVuexBooted = true;

        oidc.startSilentRenew();
    }

    created() {
        this.isVuexBooted = isVuexBooted;
        this.$bus.on('Vuex::Booted', this.vuexBootedHandler);
    }
}

export default App;
</script>
<style lang="scss">
.loading-app {
    display: flex;
    width: 100%;
    height: 1px;
    min-height: 100%;

    &--center {
        align-items: center;
        justify-content: center;
    }
}
</style>
