<template>
    <VueSelect :options="options" class="v-select-light" multiple placeholder="Город" v-model="value"></VueSelect>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
class FilterCity extends Vue {
    value = [];
    options = [
        { label: 'Владивосток', value: 'Владивосток' },
        { label: 'Ижевск', value: 'Ижевск' },
        { label: 'Набережные Челны', value: 'Набережные Челны' },
        { label: 'Нижний Новгород', value: 'Нижний Новгород' },
        { label: 'Пермь', value: 'Пермь' },
        { label: 'Севастополь', value: 'Севастополь' },
        { label: 'Сочи', value: 'Сочи' },
        { label: 'Тверь', value: 'Тверь' },
        { label: 'Тюмень', value: 'Тюмень' },
        { label: 'Уфа', value: 'Уфа' },
        { label: 'Хабаровск', value: 'Хабаровск' },
        { label: 'Ханты-Мансийск', value: 'Ханты-Мансийск' },
        { label: 'Ярославль', value: 'Ярославль' },
    ];

    getValue() {
        return this.value.map((value) => value.value);
    }

    reset() {
        this.value = [];
    }
}

export default FilterCity;
</script>
