var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VModalWrapper',_vm._g(_vm._b({attrs:{"title":_vm.title}},'VModalWrapper',_vm.attributes,false),_vm.listeners),[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{staticClass:"active-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"security-group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"request-security-group"}},[_vm._v("Группа исполнителей")]),_c('VSelect',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"request-security-group","name":"security-group","label":"name","placeholder":"Выберите группу исполнителей","options":_vm.securityGroups},on:{"input":_vm.resetExecutor},model:{value:(_vm.securityGroup),callback:function ($$v) {_vm.securityGroup=$$v},expression:"securityGroup"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"executor"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"request-executor"}},[_vm._v("Исполнитель")]),_c('VSelect',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"request-executor","name":"executor","label":"displayName","placeholder":"Выберите исполнителя","options":_vm.securityGroup ? _vm.securityGroup.accounts.filter((x) => x.enabled) : []},model:{value:(_vm.executor),callback:function ($$v) {_vm.executor=$$v},expression:"executor"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"comment"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"request-comment"}},[_vm._v("Комментарий")]),_c('VContenteditable',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"request-comment","name":"comment"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('div',{staticClass:"active-form__actions"},[_c('VButton',{staticClass:"active-form__action button_green-light",attrs:{"type":"button"},on:{"click":_vm.hide}},[_vm._v(" Отмена ")]),_c('VButton',{staticClass:"active-form__action button_green",attrs:{"type":"submit"}},[_vm._v(" Изменить ")])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }