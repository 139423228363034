<template>
    <div
        class="switch-button"
        :class="{ 'switch-button_enable': value }"
        :style="{ '--color': color, '--size': size }"
        @click="onClick"
    >
        <div class="switch-button__button"></div>
    </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';

@Component
class VSwitchButton extends Vue {
    @Prop({ type: String, default: '26px' }) size;
    @Prop({ type: String, default: '#000' }) color;
    @Prop({ type: Boolean, default: false }) value;

    @Emit('input')
    onClick() {
        return !this.value;
    }
}

export default VSwitchButton;
</script>
<style lang="scss">
.switch-button {
    width: var(--size);
    height: calc(var(--size) / 2);
    border: var(--color) 1px solid;
    border-radius: var(--size);
    box-sizing: content-box;
    transition: all 0.6s;

    &__button {
        width: calc(var(--size) / 2);
        height: calc(var(--size) / 2);
        border-radius: 50%;
        background-color: var(--color);
        border: #fff 1px solid;
        transition: all 0.6s;
    }

    &_enable {
        background-color: var(--color);

        & > .switch-button__button {
            margin-left: 50%;
            border: var(--color) 1px solid;
            background-color: var(--color-white);
        }
    }
}
</style>
