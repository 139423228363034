<template>
    <VModalWrapper :title="title" v-on="listeners" v-bind="attributes">
        <p class="error-model__message">
            {{ errorMessage }}
        </p>

        <VButton type="button" class="button_green-light" @click="hide"> Закрыть </VButton>
    </VModalWrapper>
</template>
<script>
// Components
import VButton from '../components/VButton/VButton.vue';
import VModalWrapper from '../components/VModalWrapper/VModalWrapper.vue';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalNames from '../../js/enums/ModalNames';
import ModalMixin from '../../js/vue/mixins/ModalMixin';

@Component({
    components: {
        VButton,
        VModalWrapper,
    },
})
class VErrorModal extends ModalMixin {
    @Prop({ type: String, default: ModalNames.ERROR })
    name;
    @Prop({ type: String, default: 'Уведомление' })
    defaultTitle;

    errorMessage = '';

    beforeOpen(event) {
        this.errorMessage = event?.params?.errorMessage;
    }

    beforeClose() {
        this.errorMessage = '';
    }
}

export default VErrorModal;
</script>
<style lang="scss">
.error-model {
    &__message {
        margin: 0 0 30px 0;
        font-size: 1.2rem;
        text-align: center;
    }
}
</style>
