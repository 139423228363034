import types from './RequestStatusType';

export default class RequestStatusColor {
    static [types.CLOSED] = '#0db5b5';
    static [types.DENIED] = '#f1905b';
    static [types.PAUSED] = '#f1905b';
    static [types.REWORK] = '#f1905b';
    static [types.WORKUP] = '#1ca75e';
    static [types.FINISH] = '#1ca75e';
}
