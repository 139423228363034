var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VModalWrapper',_vm._g(_vm._b({attrs:{"title":_vm.title,"is-loading":_vm.isLoading}},'VModalWrapper',_vm.attributes,false),_vm.listeners),[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{staticClass:"active-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"title","rules":"max:64"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"service-title"}},[_vm._v("Название")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"service-title","name":"title"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.service.title),callback:function ($$v) {_vm.$set(_vm.service, "title", $$v)},expression:"service.title"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"subtitle","rules":"required|max:128"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"service-subtitle"}},[_vm._v("Описание")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"service-title","name":"subtitle"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.service.subtitle),callback:function ($$v) {_vm.$set(_vm.service, "subtitle", $$v)},expression:"service.subtitle"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"hours","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"service-hours"}},[_vm._v("Время на решение обращения")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"service-title","name":"hours"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.service.hours),callback:function ($$v) {_vm.$set(_vm.service, "hours", $$v)},expression:"service.hours"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"categoryId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"service-categoryId"}},[_vm._v("Категория")]),_c('VSelect',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"security-group-categoryId","name":"categoryId","label":"title","reduce":(x) => x.id,"options":_vm.categories},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.service.categoryId),callback:function ($$v) {_vm.$set(_vm.service, "categoryId", $$v)},expression:"service.categoryId"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"observers"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"service-observers"}},[_vm._v("Наблюдатели по умолчанию")]),_c('VSelect',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"security-group-observers","name":"observers","label":"displayName","multiple":"","options":_vm.users},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.service.observers),callback:function ($$v) {_vm.$set(_vm.service, "observers", $$v)},expression:"service.observers"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"securityGroupId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"service-securityGroupId"}},[_vm._v("Группа ответственных")]),_c('VSelect',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"security-group-observers","name":"securityGroupId","label":"name","options":_vm.securityGroups,"reduce":(x) => x.id},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.service.securityGroupId),callback:function ($$v) {_vm.$set(_vm.service, "securityGroupId", $$v)},expression:"service.securityGroupId"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),(_vm.securityGroup)?_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"executorId"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"service-executorId"}},[_vm._v("Исполнитель")]),_c('VSelect',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"security-group-observers","name":"executorId","label":"displayName","reduce":(x) => x.id,"options":_vm.securityGroup.accounts,"resetOnOptionsChange":true},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.service.executorId),callback:function ($$v) {_vm.$set(_vm.service, "executorId", $$v)},expression:"service.executorId"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}):_vm._e(),_c('div',{staticClass:"active-form__actions"},[_c('VButton',{staticClass:"active-form__action button_green-light",attrs:{"type":"button"},on:{"click":_vm.hide}},[_vm._v(" Отмена ")]),_c('VButton',{staticClass:"active-form__action button_green",attrs:{"type":"submit"}},[_vm._v(" Сохранить ")])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }