import Vue from 'vue';
import validate from 'validate.js';

Vue.use({
    install(Vue) {
        this.forms = [];

        this.validateForm = (formName) => {
            const form = this.findForm(formName);
            const model = form.fields.reduce((carry, field) => {
                carry[field.name] = field.component.getValue();

                return carry;
            }, {});

            const errors = validate(model, form.rules);

            form.fields.forEach((field) => {
                field.component.onValidation(errors && errors[field.name] ? errors[field.name] : []);
            });

            return {
                model,
                errors,
            };
        };

        this.registerForm = (name, rules, fields = []) => {
            const index = this.findFormIndex(name);

            const form = {
                name,
                rules,
                fields,
            };

            index !== -1 ? this.forms.splice(index, 1, form) : this.forms.push(form);

            // this.forms.push(form);

            return form;
        };

        this.removeForm = (name) => {
            const index = this.findFormIndex(name);

            if (index !== -1) this.forms.splice(index, 1);
        };

        this.registerFormField = (formName, name, component) => {
            const form = this.findForm(formName);

            if (!form) throw `Form '${formName}' not found`;

            const field = {
                name,
                form,
                component,
            };

            form.fields.push(field);

            return field;
        };

        this.removeFormField = (formName, fieldName) => {
            const form = this.findForm(formName);

            if (!form) return;

            const index = form.fields.findIndex((field) => field.name === fieldName);

            if (index !== -1) form.fields.splice(index, 1);
        };

        this.findForm = (name) => {
            return this.forms.find((form) => form.name === name);
        };

        this.findFormIndex = (name) => {
            return this.forms.findIndex((form) => form.name === name);
        };

        Vue.prototype.$_forms = this.forms;
        Vue.prototype.$_findForm = this.findForm;
        Vue.prototype.$_findFormIndex = this.findFormIndex;
        Vue.prototype.$_validateForm = this.validateForm;
        Vue.prototype.$_registerForm = this.registerForm;
        Vue.prototype.$_removeForm = this.removeForm;
        Vue.prototype.$_registerFormField = this.registerFormField;
        Vue.prototype.$_removeFormField = this.removeFormField;
    },
});
