import axios from 'axios';
import router from '../../router';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error && error.response) {
            switch (error.response.status) {
                case 403:
                    router.push('/error/403');
                    break;
                case 500:
                    router.push('/error/500');
                    break;
            }
        }
        throw error;
    },
);

export default axios;
