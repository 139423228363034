<template>
    <form class="active-form" action="" @submit.prevent="onSubmit">
        <slot></slot>
    </form>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
class VActiveForm extends Vue {
    @Prop({ type: String, default: '', required: true }) name;
    @Prop({ type: Object, default: () => ({}) }) rules;
    @Prop({ type: Array, default: () => [] }) fields;

    form = {};

    onSubmit() {
        const { model, errors } = this.$_validateForm(this.form.name);

        if (errors) {
            return this.$emit('failed', { model, errors });
        }

        this.$emit('submit', model);
    }

    created() {
        this.form = this.$_registerForm(this.name, this.rules, this.fields);
    }

    beforeDestroy() {
        this.$_removeForm(this.form);
    }
}

export default VActiveForm;
</script>
<style lang="scss">
.active-form {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
</style>
