import Vue from 'vue';
import VModal from 'vue-js-modal';

Vue.use(VModal);

const modal = {
    install(_Vue) {
        const showModal = function (name, props) {
            return createModalPromise(this.$modal, name, props);
        };

        const createModalPromise = function (modal, name, props) {
            return new Promise((resolve, reject) => {
                const promise = {
                    reject(result) {
                        reject(result);
                        this.complied = true;
                    },
                    resolve(result) {
                        resolve(result);
                        this.complied = true;
                    },
                    complied: false,
                };

                props = {
                    ...props,
                    promise,
                };

                modal.show(name, props);
            });
        };

        _Vue.prototype.$showModal = showModal;
    },
};

Vue.use(modal);

export default VModal;
