import camelCaseFileName from '../js/camelCaseFileName';

const store = {};
const configFiles = require.context('./', true, /^(?!.*(actions|mutations|getters|index)).*\.js$/);

configFiles.keys().forEach((fileName) => {
    const name = camelCaseFileName(fileName);

    store[name] = {
        namespaced: true,
        ...configFiles(fileName).default,
    };
});

export default store;
