import types from './RequestStatusType';

export default class RequestStatusTitle {
    static [types.CLOSED] = 'Закрыто';
    static [types.DENIED] = 'Отказано';
    static [types.PAUSED] = 'Приостановлено';
    static [types.REWORK] = 'Требуются доработки';
    static [types.WORKUP] = 'Ведутся работы';
    static [types.FINISH] = 'Выполнено';
}
