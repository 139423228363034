<template>
    <VueCustomScrollbar>
        <ul class="request-history">
            <template v-for="point in history">
                <li class="request-history__point request-history-point" :key="point.id">
                    <span class="request-history-point__header">
                        <h4
                            class="request-history-point__title"
                            :style="{ color: getHistoryPointColor(point) }"
                            v-html="getHistoryPointTitle(point)"
                        ></h4>

                        <span class="request-history-point__created-at" :title="$_toLocalDateHistory(point.createdAt)">
                            {{ $_toLocalRelativeDate(point.createdAt) }}
                        </span>
                    </span>

                    <UserCard class="request-history-point__author" :account="point.author"></UserCard>
                </li>
            </template>
        </ul>
    </VueCustomScrollbar>
</template>
<script>
// Components
import UserCard from '@/views/components/VUser/UserCard.vue';
import VueCustomScrollbar from 'vue-custom-scrollbar';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import RequestHistoryActionTitle from '@/js/enums/RequestHistoryActionTitle';
import RequestHistoryActionColor from '@/js/enums/RequestHistoryActionColor';
import { namespace } from 'vuex-class';

const Workspace = namespace('workspace');

@Component({
    components: {
        UserCard,

        VueCustomScrollbar,
    },
})
class RequestViewTabHistory extends Vue {
    @Prop({ type: Object, required: true })
    request;

    @Prop({ type: Array, required: true })
    history;

    @Workspace.State accounts;
    @Workspace.State securityGroups;

    findAccount(id) {
        return this.accounts.find((account) => account.id == id);
    }

    findSecurityGroup(id) {
        return this.securityGroups.find((securityGroup) => securityGroup.id == id);
    }

    getHistoryPointColor(point) {
        return RequestHistoryActionColor[point.action];
    }

    getHistoryPointTitle(point) {
        const meta = JSON.parse(point.meta);
        const action = RequestHistoryActionTitle[point.action];

        return action.replace('{_executor_}', this.getExecutorName(meta));
    }

    getExecutorName(meta) {
        let executor = '';

        if (meta && meta.executorId) {
            executor = this.findAccount(meta.executorId)?.displayName;
        }

        if (meta && meta.securityGroup) {
            executor = this.findSecurityGroup(meta.securityGroup)?.name;
        }

        return executor;
    }
}

export default RequestViewTabHistory;
</script>
<style lang="scss">
:root {
    --request-history-title-color: #484848;
    --request-history-created-at-color: #aaaaaa;
    --request-history-point-border: #e4eaef;
}

.request-history {
    margin: 24px;
}

.request-history-point {
    margin: 16px 0 0 0;
    padding: 16px;
    border: 1px solid var(--request-history-point-border);
    border-radius: 12px;

    &__header {
        display: flex;
    }

    &__title {
        margin: 0 8px 0 0;
        font-size: 0.875rem;
        line-height: 1rem;
        font-weight: 500;
        color: var(--request-history-title-color);

        @media (min-width: $lg-desktop-breakpoint) {
            font-size: 1rem;
            line-height: 1.125rem;
        }
    }

    &__author {
        margin: 12px 0 0 0;
    }

    &__created-at {
        font-size: 0.75rem;
        line-height: 1.0625rem;
        color: var(--request-history-created-at-color);

        @media (min-width: $lg-desktop-breakpoint) {
            font-size: 0.875rem;
            line-height: 1.1875rem;
        }
    }
}
</style>