import './VGrid.scss';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref } from 'vue-property-decorator';

@Component
class GridPage extends Vue {
    offset = 0;
    convertToKebabCase = require('lodash/kebabCase');

    @Ref('components')
    componentRefs;

    get componentNames() {
        return Object.keys(this.components);
    }

    get components() {
        return this.route && this.route.components ? this.route.components : { default: 'default' };
    }

    get route() {
        return this.$router.options.routes.find(({ name }) => {
            return name === this.$route.name;
        });
    }

    get offsetWidthPercent() {
        return `${-(this.offset * 100)}%`;
    }

    onTouchSwipeLeft() {
        if (this.$isDesktop()) {
            return;
        }

        this.offsetToRight();
    }

    onTouchSwipeRight() {
        if (this.$isDesktop()) {
            return;
        }

        this.offsetToLeft();
    }

    offsetToLeft() {
        if (this.offset === 0) {
            return this.emitEventShowMenu();
        }

        this.offset -= 1;
        this.$bus.emit('grid-page:offset-left');
    }

    offsetToRight() {
        if (this.offset + 1 < this.componentsCount()) {
            this.offset += 1;
        }
        this.$bus.emit('grid-page:offset-right');
    }

    emitEventShowMenu() {
        this.$bus.emit('grid-page:show-menu');
    }

    componentsWidth() {
        return this.componentRefs.reduce((carry, componentRef) => (carry += componentRef.$el.offsetWidth), 0);
    }

    componentsCount() {
        const count = this.componentsWidth() / window.innerWidth;

        if (!Number.isInteger(count)) {
            throw new Error('The size of the components relative to the page is not an integer.');
        }

        return count;
    }

    render() {
        return this.renderGridPage([this.renderRouterView()]);
    }

    renderGridPage(childrens) {
        const data = {
            staticClass: 'grid-page',
            attrs: {
                style: `margin-left: ${this.offsetWidthPercent}`,
            },
            directives: [
                {
                    name: 'touch',
                    arg: 'swipe',
                    value: this.onTouchSwipeLeft,
                    modifiers: {
                        left: true,
                    },
                },
                {
                    name: 'touch',
                    arg: 'swipe',
                    value: this.onTouchSwipeRight,
                    modifiers: {
                        right: true,
                    },
                },
            ],
        };

        return this.$createElement('div', data, childrens);
    }

    renderRouterView() {
        return this.componentNames.map((name, index) => {
            const routerViewData = {
                ref: 'components',
                refInFor: true,
                key: index,
                props: {
                    key: index,
                },
                attrs: {
                    name,
                    index,
                    column: this.convertToKebabCase(name),
                },
            };

            return this.$createElement('RouterView', routerViewData);
        });
    }
}

export default GridPage;
