import NavMenu from '../views/pages/Shared/NavMenu.vue';
import { RequestCreateStoreCategory, RequestCreateStoreService, RequestCreateView } from '../views/pages/RequestCreate';
import oidc from '../vue-oidc-client';

export default [
    //aliases for legacy routing
    {
        name: 'request.create',
        path: '/request-create',
        redirect: '/request/categories',
        meta: {
            authName: oidc.authName,
        },
    },

    {
        name: 'request.create.categories',
        path: '/request/categories',
        components: {
            NavMenu,
            RequestCreateStoreCategory,
        },
        meta: {
            authName: oidc.authName,
        },
    },

    {
        name: 'request.create.services',
        path: '/request/categories/:categoryId/services',
        components: {
            NavMenu,
            RequestCreateStoreService,
        },
        meta: {
            authName: oidc.authName,
        },
    },

    {
        name: 'request.create.form',
        path: '/request/categories/:categoryId/services/:serviceId',
        components: {
            NavMenu,
            RequestCreateStoreService,
            RequestCreateView,
        },
        meta: {
            authName: oidc.authName,
        },
    },
];
