export default {
    LOGIN(state, account) {
        state.account = account;
        state.authorized = true;
    },

    LOGOUT(state) {
        state.account = {};
        state.authorized = false;
    },

    CREATE_REQUEST_FILTER_SETTINGS(state, requestFilterSettings) {
        state.account.requestFilterSettings = requestFilterSettings;
    },

    UPDATE_REQUEST_FILTER_SETTINGS(state, requestFilterSettings) {
        Object.keys(requestFilterSettings).forEach(
            (name) => (state.account.requestFilterSettings[name] = requestFilterSettings[name]),
        );
    },

    REQUEST_STORE_SETTINGS_UPDATE({ account }, requestStoreSettings) {
        account.requestStoreSettings = requestStoreSettings;
    },
};
