<template>
    <div :style="layer" class="module__column module__column_layer">
        <slot></slot>
    </div>
</template>
<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
class ColumnLayer extends Vue {
    @Prop(Boolean) value;
    @Prop({ type: String, default: '100' }) zIndex;

    get layer() {
        return {
            'height': this.value ? '100%' : 0,
            'opacity': this.value ? 1 : 0,
            'z-index': this.zIndex,
        };
    }
}

export default ColumnLayer;
</script>
<style lang="scss">
.module {
    &__column {
        display: flex;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &_layer {
            position: absolute;
            left: 0;
            bottom: 0;
            transition: 0.5s;
            background-color: $default-color-white;
        }
    }
}
</style>
