<template>
    <VModalWrapper :title="title" v-on="listeners" v-bind="attributes" :is-loading="isLoading">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form class="active-form" @submit.prevent="handleSubmit(submit)">
                <ValidationProvider class="active-form__field" name="group" rules="required" v-slot="{ errors }">
                    <label class="active-form__label active-form__label_required" for="bitrix24-group">Достка</label>

                    <VSelect
                        id="bitrix24-group"
                        name="group"
                        label="name"
                        class="active-form__control"
                        placeholder="Выберите доску"
                        :class="{
                            error: errors[0],
                        }"
                        :options="bitrix24Groups"
                        v-model="group"
                    ></VSelect>

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider class="active-form__field" name="title" v-slot="{ errors }">
                    <label class="active-form__label" for="bitrix24-title">Название задачи</label>

                    <VContenteditable
                        id="bitrix24-title"
                        name="title"
                        class="active-form__control"
                        placeholder="Название задачи..."
                        :class="{
                            error: errors[0],
                        }"
                        v-model="taskTitle"
                    ></VContenteditable>

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <div class="active-form__actions">
                    <VButton type="button" class="active-form__action button_green-light" @click="hide">
                        Отмена
                    </VButton>

                    <VButton type="submit" class="active-form__action button_green"> Отправить </VButton>
                </div>
            </form>
        </ValidationObserver>
    </VModalWrapper>
</template>
<script>
// Components
import VButton from '../components/VButton/VButton.vue';
import VSelect from '../components/VSelect/VSelect.vue';
import VModalWrapper from '../components/VModalWrapper/VModalWrapper.vue';
import VContenteditable from '../components/VContenteditable/VContenteditable.vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalNames from '../../js/enums/ModalNames';
import ModalMixin from '../../js/vue/mixins/ModalMixin';
import RequestStatusType from '../../js/enums/RequestStatusType';
import { getUserGroupsFromBitrix24 } from '../../js/api';
import RequestStatusTitle from '../../js/enums/RequestStatusTitle';

@Component({
    components: {
        VButton,
        VSelect,
        VModalWrapper,
        VContenteditable,

        ValidationObserver,
        ValidationProvider,
    },
})
class VRequestSendToBitrix24Modal extends ModalMixin {
    @Prop({ type: String, default: ModalNames.REQUEST_SEND_TO_BITRIX24 })
    name;
    @Prop({ type: String, default: 'Выберите доску, на которую вы хотите отправить задачу' })
    defaultTitle;

    isLoading = false;
    bitrix24Groups = [];

    group = null;
    taskTitle = null;

    submit() {
        this.resolve({
            title: this.taskTitle,
            groupId: this.group.id,
        });

        this.hide();
    }

    async beforeOpen() {
        if (this.bitrix24Groups.length > 0) {
            return;
        }

        this.isLoading = true;
        try {
            const response = await getUserGroupsFromBitrix24();

            this.bitrix24Groups = response.data.GROUPS.map((group) => ({
                id: group.ID,
                name: group.NAME,
            }));
        } finally {
            this.isLoading = false;
        }
    }

    beforeClose() {
        this.group = null;
        this.taskTitle = null;
        this.status = {
            label: RequestStatusTitle[RequestStatusType.FINISH],
            value: RequestStatusType.FINISH,
        };
    }
}

export default VRequestSendToBitrix24Modal;
</script>
