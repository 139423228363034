import NavMenu from '../views/pages/Shared/NavMenu.vue';
import { ExecutiveTable } from '../views/pages/ExecutiveTable';
import oidc from '../vue-oidc-client';

export default [
    {
        name: 'executive-table',
        path: '/executive-table',
        components: {
            NavMenu,
            ExecutiveTable,
        },
        meta: {
            authName: oidc.authName,
        },
    },
];
