<template>
    <VButton class="button_dropdown" v-on="listeners">
        <slot></slot>

        <ul
            class="button-dropdown-items"
            :class="{
                'button-dropdown-items_bottom-left': bottomLeft,
                'button-dropdown-items_bottom-right': bottomRight,
            }"
            v-if="isShowDropdown"
        >
            <template v-for="(action, index) in actions">
                <li class="button-dropdown-items__item" :key="index" @click.stop="callAction(action.action)">
                    {{ action.name }}
                </li>
            </template>
        </ul>
    </VButton>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import VButton from './VButton.vue';

@Component({
    components: {
        VButton,
    },
})
class VButtonDropdown extends Vue {
    @Prop({ type: Array, required: true })
    actions;

    @Prop({ type: Boolean, default: true })
    bottomLeft;

    @Prop({ type: Boolean, default: false })
    bottomRight;

    isShowDropdown = false;

    callAction(action) {
        this.isShowDropdown = false;

        if (action) {
            action();
        }
    }

    get listeners() {
        return {
            ...this.$listeners,
            click: (event) => {
                event.preventDefault();
                this.isShowDropdown = !this.isShowDropdown;
            },
            blur: (event) => {
                event.preventDefault();
                this.isShowDropdown = false;
            },
        };
    }
}

export default VButtonDropdown;
</script>
<style lang="scss">
:root {
    --button-dropdown-color: var(--color-white);
    --button-dropdown-background: #484848;
    --button-dropdown-background-hover: var(--color-green);
}

.button_dropdown {
    position: relative;
    line-height: 0.875rem;
}

.button-dropdown-items {
    position: absolute;
    min-width: 260px;
    padding: 8px 0;
    background: var(--button-dropdown-background);
    box-shadow: 0px 3px 13px 1px rgba(98, 98, 98, 0.25);
    border-radius: 8px;

    &_bottom-left {
        right: 0;
        top: 100%;
        margin: 4px 0 0 0;
    }

    &_bottom-right {
        left: 0;
        top: 100%;
        margin: 4px 0 0 0;
    }

    &__item {
        white-space: nowrap;
        font-size: 1rem;
        line-height: 1.4;
        padding: 4px 16px;
        text-align: left;
        color: var(--button-dropdown-color);

        &:hover {
            background-color: var(--button-dropdown-background-hover);
        }
    }
}
</style>
