import toCamelCase from 'lodash/camelCase';

const config = {};
const requireConfig = require.context('.', false, /^(?!.*(index)).*\.js$/);

requireConfig.keys().forEach((fileName) => {
    const name = fileName.replace(/^.+\//, '').replace(/\.\w+$/, '');

    config[toCamelCase(name)] = { ...requireConfig(fileName).default };
});

export default config;
