<template>
    <div class="file-viewer">
        <div class="file-viewer__wrap" v-show="value && value.length">
            <template v-for="(model, i) in value">
                <FileViewerModel :key="i" :model="model">
                    <div
                        class="file-viewer__layer file-viewer__layer_right file-viewer__layer_align-center"
                        v-if="upload"
                    >
                        <div class="file-viewer__progress" v-if="model.isUploading && model.progress <= 100">
                            <div class="file-viewer__progress-bar">
                                <div :style="progressWidth(model.progress)" class="file-viewer__progress-line"></div>
                            </div>
                        </div>
                        <div class="file-viewer__error" v-if="model.errorMessage">
                            <span class="file-viewer__error-title">{{ model.errorMessage }}</span>
                        </div>
                        <VButton
                            @click.stop.prevent="remove(model, i)"
                            class="button_orange file-viewer__button file-viewer__button_remove"
                        >
                            <WindowCloseIcon></WindowCloseIcon>
                        </VButton>
                    </div>
                </FileViewerModel>
            </template>
        </div>
    </div>
</template>
<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

import FileViewerModel from './FileViewerModel';
import FileUploaderErrorTitle from '@/js/enums/FileUploaderErrorTitle';
import VButton from '@/views/components/VButton/VButton.vue';
import WindowCloseIcon from 'vue-material-design-icons/WindowClose';

@Component({
    components: {
        FileViewerModel,
        WindowCloseIcon,
        VButton,
    },
})
class FileViewer extends Vue {
    @Prop({ type: Array })
    value;
    @Prop({ type: Boolean, default: false })
    upload;

    remove(remove, i) {
        this.value.splice(i, 1);
    }

    errorTitle(FileUploaderErrorType) {
        return FileUploaderErrorTitle[FileUploaderErrorType];
    }

    progressWidth(value) {
        return `width: ${value}%`;
    }
}

export default FileViewer;
</script>
<style lang="scss">
.file-viewer {
    position: relative;

    &__wrap {
        display: flex;
        margin: 5px 0;
        flex-direction: column;
    }

    &__layer {
        display: flex;
        top: 0;
        height: 100%;
        margin: 0 15px;
        padding: 5px 0;
        position: absolute;

        &_left {
            left: 0;
        }

        &_right {
            right: 0;
        }

        &_align {
            &-center {
                align-items: center;
            }
        }
    }

    &__button {
        &_remove {
            width: 24px;
            height: 24px;
            margin: 0;
            padding: 0;
        }
    }

    &__progress {
        display: flex;
        width: 50vw;
        height: 100%;
        max-width: 400px;
        padding: 0 15px;
        align-items: center;

        &-bar {
            width: 100%;
            height: 6px;
            border-radius: 5px;
            background-color: #ababab;
        }

        &-line {
            height: 100%;
            border-radius: 5px;
            background-color: $default-color-green;
        }
    }

    &__error {
        padding: 0 15px;

        &-title {
            color: $default-color-error;
            font: 400 0.9rem Roboto;
        }
    }
}
</style>
