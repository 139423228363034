<template>
    <VGridPageColumn sm-10>
        <template v-if="isLoadingRenderValue">
            <VGridRow class="grid-row_fluid grid-row_center">
                <VSpinner></VSpinner>
            </VGridRow>
        </template>

        <template v-else>
            <VGridRow class="grid-row_padding-bottom">
                <VGridCol sm-12 md-5 lg-4 xl-3>
                    <label>Период:</label>
                    <VGridFlex>
                        <FlatPickr
                            id="date-period"
                            :config="datePeriodConfig"
                            class="input input_light"
                            placeholder="Период"
                            required
                            v-model="datePeriodValue"
                        ></FlatPickr>
                        <VButton @click="datePeriodSubmit" class="button_small button_green button_space-left"
                            >Применить</VButton
                        >
                    </VGridFlex>
                </VGridCol>
                <VGridCol class="grid-col_row grid-col_justify-end" col-6 v-if="false">
                    <VGridFlex class="grid-flex_relative" @click.stop.prevent>
                        <VButton @click="toggleRenderSettingsMenu" class="button_small button_light"
                            >Настройка отображения</VButton
                        >
                        <VGridFlex
                            class="grid-flex_absolute executive-table__filed-setting-menu"
                            v-if="showRenderSettingsMenu"
                        >
                            <FieldSettingsMenu :render-fields="renderColumns"></FieldSettingsMenu>
                        </VGridFlex>
                    </VGridFlex>
                    <VGridFlex class="grid-flex_relative" @click.stop.prevent>
                        <VButton @click="toggleFieldsSettingsMenu" class="button_small button_light button_space-left"
                            >Настройка полей</VButton
                        >
                        <VGridFlex
                            class="grid-flex_absolute executive-table__filed-setting-menu"
                            v-if="showFieldsSettingsMenu"
                        >
                            <FieldSettingsMenu :render-fields="renderColumns"></FieldSettingsMenu>
                        </VGridFlex>
                    </VGridFlex>
                </VGridCol>
            </VGridRow>

            <VGridScroll class="grid-scroll_hidden">
                <VGridRow class="grid-row_without-padding" v-for="(name, id) in renderGroups" :key="id">
                    <VGridCol class="grid-col_padding-y">
                        <VGridRow>
                            <span class="text-xl">{{ name }}</span>
                        </VGridRow>

                        <ExecutiveTableRender
                            :render-values="renderValues[id]"
                            :render-columns="renderColumns"
                            :render-total-columns="userSettingsRenderColumns"
                        ></ExecutiveTableRender>
                    </VGridCol>
                </VGridRow>
            </VGridScroll>
        </template>
    </VGridPageColumn>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import http from '../../../js/http';
import { Russian } from 'flatpickr/dist/l10n/ru.js';

import VButton from '../../components/VButton/VButton.vue';
import VSpinner from '@/views/components/VProgress/VSpinner.vue';
import { VGridCol, VGridRow, VGridFlex, VGridScroll, VGridPageColumn } from '../../components';
import { FieldSettingsMenu, ExecutiveTableRender } from './components';

@Component({
    components: {
        VGridCol,
        VGridRow,
        VGridFlex,
        VGridScroll,
        VGridPageColumn,

        VButton,
        VSpinner,

        FieldSettingsMenu,
        ExecutiveTableRender,
    },
})
class ExecutiveTable extends Vue {
    isLoadingRenderValue = true;
    showFieldsSettingsMenu = false;
    showRenderSettingsMenu = false;

    datePeriodValue = '';
    datePeriodConfig = {
        mode: 'range',
        weekNumbers: true,
        dateFormat: 'd.m.Y',
        locale: Russian,
    };

    renderType = {};
    renderValues = [];
    renderGroups = [];
    renderColumns = [];

    settingsRenderTypes = [];
    settingsRenderColumns = [];

    userSettingsRenderType = {};
    userSettingsRenderColumns = [];

    toggleFieldsSettingsMenu() {
        this.hide();
        this.showFieldsSettingsMenu = !this.showFieldsSettingsMenu;
    }

    toggleRenderSettingsMenu() {
        this.hide();
        this.showRenderSettingsMenu = !this.showRenderSettingsMenu;
    }

    hide() {
        this.showFieldsSettingsMenu = false;
        this.showRenderSettingsMenu = false;
    }

    loadExecutiveTableRenderData() {
        this.isLoadingRenderValue = true;

        http.get(`/b/executive-table?period=${this.datePeriodValue}`).then(
            ({ data: { render, settings, userSettings } }) => {
                this.renderType = render.renderType;
                this.renderValues = render.renderValues;
                this.renderGroups = render.renderGroups;
                this.renderColumns = render.renderColumns;

                this.settingsRenderTypes = settings.renderTypes;
                this.settingsRenderColumns = settings.renderColumns;

                this.userSettingsRenderType = userSettings.renderType;
                this.userSettingsRenderColumns = userSettings.renderColumns;

                this.isLoadingRenderValue = false;
            },
        );
    }

    datePeriodSubmit() {
        this.updateCurrentPerion();
        this.loadExecutiveTableRenderData();
    }

    updateCurrentPerion() {
        this.datePeriodValue = this.datePeriodValue ? this.datePeriodValue : this.$route.query.period;

        if (!this.datePeriodValue || this.datePeriodValue.length === 0) {
            const date = new Date();
            const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            this.datePeriodValue = '{0}.{1}.{2} — {3}.{4}.{5}'.formatUnicorn(
                firstDay.getDate(),
                firstDay.getMonth() + 1,
                firstDay.getFullYear(),
                lastDay.getDate(),
                lastDay.getMonth() + 1,
                lastDay.getFullYear(),
            );
        }

        this.$router.replace({
            name: 'executive-table',
            query: {
                period: this.datePeriodValue,
            },
        });
    }

    created() {
        this.updateCurrentPerion();
        this.loadExecutiveTableRenderData();

        document.addEventListener('click', this.hide);
    }

    beforeDestroy() {
        document.removeEventListener('click', this.hide);
    }
}

export default ExecutiveTable;
</script>
<style lang="scss">
.executive-table {
    &__filed-setting-menu {
        top: 30px;
        right: 0;
        z-index: 1010;
    }
}
</style>
