var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VModalWrapper',_vm._g(_vm._b({attrs:{"title":_vm.title,"is-loading":_vm.isLoading}},'VModalWrapper',_vm.attributes,false),_vm.listeners),[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{staticClass:"active-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"label","rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"security-group-label"}},[_vm._v("Название")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"security-group-label","name":"label"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.securityGroup.name),callback:function ($$v) {_vm.$set(_vm.securityGroup, "name", $$v)},expression:"securityGroup.name"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"commonName","rules":"required|max:128"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"security-group-commonName"}},[_vm._v("Общее имя (Active Directory)")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"security-group-commonName","name":"commonName"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.securityGroup.commonName),callback:function ($$v) {_vm.$set(_vm.securityGroup, "commonName", $$v)},expression:"securityGroup.commonName"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"location","rules":"required|max:48"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"security-group-location"}},[_vm._v("Часовой пояс")]),_c('VSelect',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"security-group-location","name":"location","label":"name","options":_vm.timezones},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.securityGroup.timezone),callback:function ($$v) {_vm.$set(_vm.securityGroup, "timezone", $$v)},expression:"securityGroup.timezone"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"location"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"security-group-location"}},[_vm._v("Учасники")]),_c('VSelect',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"security-group-location","name":"location","label":"displayName","multiple":"","options":_vm.users},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.securityGroup.accounts),callback:function ($$v) {_vm.$set(_vm.securityGroup, "accounts", $$v)},expression:"securityGroup.accounts"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('div',{staticClass:"active-form__actions"},[_c('VButton',{staticClass:"active-form__action button_green-light",attrs:{"type":"button"},on:{"click":_vm.hide}},[_vm._v(" Отмена ")]),_c('VButton',{staticClass:"active-form__action button_green",attrs:{"type":"submit"}},[_vm._v(" Сохранить ")])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }