<template>
    <div class="admin-page">
        <AdminMenu class="admin-page__menu"></AdminMenu>

        <RouterView class="admin-page__content"></RouterView>
    </div>
</template>
<script>
// Components
import AdminMenu from './components/AdminMenu.vue';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    components: {
        AdminMenu,
    },
})
class AdminPage extends Vue {}

export default AdminPage;
</script>
<style lang="scss">
:root {
    --admin-page-background-color: #f8f8f8;
    --admin-page-menu-background-color: #ffffff;
}

.admin-page {
    display: flex;
    width: 100%;
    background-color: var(--admin-page-background-color);

    &__menu {
        flex: 0 0 290px;
        max-width: 290px;
        background-color: var(--admin-page-menu-background-color);
    }

    &__loader {
        display: flex;
        padding: 40px 0;
        justify-content: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
}
</style>
