<template>
    <div class="request-store request-store_loader">
        <template v-if="!isLoading">
            <ul class="request-store__list">
                <template v-for="request in requests">
                    <li :key="request.id" class="request-store__item">
                        <slot :request="request">{{ request.id }}</slot>
                    </li>
                </template>
            </ul>

            <PagePaginate :count="perPage" :total="total" @change="handler" v-model="currentPage">
            </PagePaginate>
        </template>

        <div class="request-store__loading" v-else>
            <VSpinner></VSpinner>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import http from '../../../js/http';
import Component from 'vue-class-component';
import { Prop, Emit, Watch } from 'vue-property-decorator';

import VSpinner from '@/views/components/VProgress/VSpinner';
import PagePaginate from '@/views/components/PagePaginate';

@Component({
    components: {
        VSpinner,
        PagePaginate,
    },
})
class RequestStorePagination extends Vue {
    @Prop({ type: Number, default: 1 }) value;
    @Prop({ type: Number, default: 5 }) perPage;
    @Prop({ type: String, default: '' }) endpoint;

    requests = [];
    total = 1;
    filter = {};
    totalPages = 1;
    currentPage = 1;
    isLoading = false;

    @Emit('loaded')
    @Emit('request-store:loaded')
    loaded() {
        return this.getEventData();
    }

    handler(page) {
        this.isLoading = true;

        //Костыль надо убрать как будет возможность
        //Убераю 1 запрос так как он дублируший
        if (!this.filter.sort) {
            return false;
        }

        const data = {
            page,
            perPage: this.perPage,
            ...this.filter,
        };

        http.post(this.endpoint, data).then(({ data: response }) => {
            this.total = response.total;
            this.totalPages = response.last_page;
            this.currentPage = response.current_page;

            this.$emit('input', this.currentPage);

            this.requests = response.data;

            this.isLoading = false;

            this.loaded();
        });
    }

    getEndpoint(page) {
        const endpoint = new URL(this.endpoint, window.origin);

        endpoint.searchParams.set('page', page);
        endpoint.searchParams.set('per_page', this.perPage);

        return endpoint;
    }

    getEventData() {
        return {
            requests: this.requests,
            totalPages: this.totalPages,
            currentPage: this.currentPage,
        };
    }

    onFilterUpdate(filter) {
        this.filter = filter;

        this.handler(1);
    }

    onFilterUpdateForce(filter) {
        this.filter = filter;
    }

    @Watch('endpoint')
    watchEndpoint() {
        this.handler(1);
    }

    created() {
        this.currentPage = this.value;
        this.handler(this.currentPage);

        this.$bus.on('RequestStoreFilter:Update', this.onFilterUpdate);
        this.$bus.on('RequestStoreFilter:UpdateForce', this.onFilterUpdateForce);
    }

    beforeDestroy() {
        this.$bus.off('RequestStoreFilter:Update', this.onFilterUpdate);
        this.$bus.off('RequestStoreFilter:UpdateForce', this.onFilterUpdateForce);
    }
}

export default RequestStorePagination;
</script>
<style lang="scss">
.request-store {
    &__loading {
        display: flex;
        width: 100%;
        height: 200px;
        align-items: center;
        justify-content: center;
    }
}
</style>
