<template>
    <nav class="header__col_menu">
        <ul class="header__nav">
            <li :key="i" class="header__button" v-for="(link, i) in links">
                <RouterLink :to="link.href" class="header__link" header-link>
                    <span class="header__text">{{ link.text }}</span>
                    <span class="header__count" v-if="link.count">{{ link.count }}</span>
                </RouterLink>
            </li>
        </ul>
    </nav>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
class VAppHeaderMenu extends Vue {
    get links() {
        return [
            {
                href: '/',
                text: 'Обращения',
            },
        ];
    }
}

export default VAppHeaderMenu;
</script>
<style lang="scss">
.router-link-active[header-link] {
    background-color: #e5e5e5;
    border-bottom: 3px solid #21c46e;
}

.header {
    &__nav {
        height: 100%;
        margin: 0 30px;
        padding: 0;
        list-style: none;
        user-select: none;
    }

    &__button {
        height: 100%;
        float: left;
    }

    &__link {
        display: flex;
        height: 100%;
        padding: 0 30px;
        color: var(--text-black);
        box-sizing: border-box;
        align-items: center;
        text-decoration: none;

        &_active [router-link-active] {
            background: #f00;
        }
    }

    &__text {
        margin: 0 5px;
        font: 400 0.9rem Roboto;
        text-transform: uppercase;
    }

    &__count {
        width: 29px;
        height: 29px;
        margin: 0 5px;
        color: var(--color-white);
        text-align: center;
        line-height: 29px;
        border-radius: 50%;
        background-color: #49b97e;
        font: 400 0.9rem/29px Roboto;
    }
}
</style>
