<template>
    <form class="search-field" @submit.prevent="blur">
        <input
            ref="input"
            class="search-field__input"
            type="text"
            v-bind="$attrs"
            :value="searchString"
            @input="onInput"
            @keydown.esc="blur"
        />

        <template v-if="!searchString">
            <div class="search-field__action" @click="focus">
                <MagnifierSvg class="search-field__action-magnifier"></MagnifierSvg>
            </div>
        </template>

        <template v-else>
            <div class="search-field__action" @click="clear">
                <CloseSvg class="search-field__action-clear" @click="clear"></CloseSvg>
            </div>
        </template>
    </form>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

// Svg
import CloseSvg from '@/images/close.svg';
import MagnifierSvg from '@/images/magnifier.svg';
import { Prop } from 'vue-property-decorator';

@Component({
    inheritAttrs: false,
    components: {
        CloseSvg,
        MagnifierSvg,
    },
})
class VSearchField extends Vue {
    @Prop({ type: Boolean, default: false })
    withTimeout;

    timeout = null;
    searchString = '';

    blur() {
        if (document?.activeElement) {
            document.activeElement.blur();
        }
    }

    clear() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.setValue('');
    }

    focus() {
        if (this.$refs.input) {
            this.$refs.input.focus();
        }
    }

    onInput(event) {
        if (this.withTimeout) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.setValue(event.target.value);
                this.timeout = null;
            }, 600);
        } else {
            this.setValue(event.target.value);
        }
    }

    setValue(value) {
        this.searchString = value;
        this.$emit('input', this.searchString);
    }
}

export default VSearchField;
</script>
<style lang="scss">
:root {
    --search-field-color-text-main: #515151;
    --search-field-placeholder-color: #94a6b0;
}

.search-field {
    position: relative;
    min-width: 16.25rem;

    &__input {
        width: 100%;
        border: none;
        outline: none;

        &::-webkit-input-placeholder {
            /* Chrome */
            color: --search-field-placeholder-color;
        }

        &:-ms-input-placeholder {
            /* IE 10+ */
            color: --search-field-placeholder-color;
        }

        &::-moz-placeholder {
            /* Firefox 19+ */
            color: --search-field-placeholder-color;
            opacity: 1;
        }

        &:-moz-placeholder {
            /* Firefox 4 - 18 */
            color: --search-field-placeholder-color;
            opacity: 1;
        }

        &,
        &--light {
            letter-spacing: 0.02em;
            font-size: 0.875rem;
            border-radius: 0.625rem;
            color: var(--search-field-color-text-main);
            background: var(--color-white);
        }
    }

    &__input,
    &__action {
        height: 2.5625rem;
        padding: 0.625rem 0.625rem 0.625rem 2.5625rem;
        box-sizing: border-box;
    }

    &__action {
        position: absolute;
        width: 2.5625rem;
        padding: 0.625rem 0.625rem;
        top: 0;
        left: 0;
        bottom: 0;
        cursor: pointer;
    }

    &__action-clear,
    &__action-magnifier {
        width: 1.125rem;
        height: 1.125rem;
        padding: 0.0625rem;
        box-sizing: content-box;
        fill: var(--search-field-color-text-main);
    }

    &__action-magnifier {
        cursor: text;
    }
}
</style>
