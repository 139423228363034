<template>
    <div class="resource-item">
        <div class="resource-item__row">
            <UserCard :account="observer"> </UserCard>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import UserCard from '../VUser/UserCard';

@Component({
    components: {
        UserCard,
    },
})
class VRequestObservers extends Vue {
    /** @type {Account} **/
    @Prop({ type: Object, default: () => [] }) observer;
}

export default VRequestObservers;
</script>
