var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VModalWrapper',_vm._g(_vm._b({attrs:{"title":_vm.title}},'VModalWrapper',_vm.attributes,false),_vm.listeners),[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{staticClass:"active-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"request-category"}},[_vm._v("Категория")]),_c('VSelect',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"request-category","name":"category","label":"title","placeholder":"Выберите категорию","options":_vm.categories},on:{"input":_vm.resetService},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"service","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"request-service"}},[_vm._v("Сервис")]),_c('VSelect',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"request-service","name":"service","label":"title","placeholder":"Выберите сервис","options":_vm.category ? _vm.category.services : []},model:{value:(_vm.service),callback:function ($$v) {_vm.service=$$v},expression:"service"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"comment"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"request-comment"}},[_vm._v("Комментарий")]),_c('VContenteditable',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"request-comment","name":"comment"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('span',{staticClass:"active-form__field"},[_c('InputCheckbox',{staticClass:"active-form__control",model:{value:(_vm.isKeepExecutor),callback:function ($$v) {_vm.isKeepExecutor=$$v},expression:"isKeepExecutor"}},[_vm._v("Оставить текущего исполнителя")])],1),_c('div',{staticClass:"active-form__actions"},[_c('VButton',{staticClass:"active-form__action button_green-light",attrs:{"type":"button"},on:{"click":_vm.hide}},[_vm._v(" Отмена ")]),_c('VButton',{staticClass:"active-form__action button_green",attrs:{"type":"submit"}},[_vm._v(" Изменить ")])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }