<template>
    <aside class="admin-menu">
        <ul class="admin-menu__list">
            <li class="admin-menu__item" v-for="(item, index) in items" :key="index">
                <RouterLink :to="item.route" class="admin-menu__button" admin-menu>
                    <component class="admin-menu__button-icon" :is="item.icon"></component>
                    <span> {{ item.label }} </span>
                </RouterLink>
            </li>
        </ul>
    </aside>
</template>
<script>
// Svg
import UserSvg from '@/images/user.svg';
import UsersSvg from '@/images/users.svg';
import CitiesSvg from '@/images/cities.svg';
import ServicesSvg from '@/images/services.svg';
import CategoriesSvg from '@/images/categories.svg';

// Components

// Other
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    components: {
        UserSvg,
        UsersSvg,
        CitiesSvg,
        ServicesSvg,
        CategoriesSvg,
    },
})
class AdminMenu extends Vue {
    /**
     * Gets the admin menu items.
     */
    items = [
        {
            icon: UserSvg,
            label: 'Пользователи',
            route: { name: 'admin-panel.users' },
        },
        {
            icon: CategoriesSvg,
            label: 'Категории',
            route: { name: 'admin-panel.categories' },
        },
        {
            icon: ServicesSvg,
            label: 'Сервисы',
            route: { name: 'admin-panel.services' },
        },
        {
            icon: CitiesSvg,
            label: 'Города',
            route: { name: 'admin-panel.cities' },
        },
        {
            icon: UsersSvg,
            label: 'Группы ответственных',
            route: { name: 'admin-panel.security-groups' },
        },
    ];
}

export default AdminMenu;
</script>
<style lang="scss">
:root {
    --admin-menu-button-active-color: var(--color-green);
    --admin-menu-button-text-active-color: var(--color-white);
}

.router-link-exact-active[admin-menu] {
    font-weight: 500;
    color: var(--admin-menu-button-text-active-color);
    background-color: var(--admin-menu-button-active-color);
}

.admin-menu {
    width: 100%;
    height: 100%;

    &__list {
        margin: 24px 0;
    }

    &__item {
        margin: 0 24px;
    }

    &__button {
        display: flex;
        padding: 12px 15px;
        border-radius: 8px;
        font-size: 1.125rem;
        line-height: 1.3125rem;
        align-items: center;
    }

    &__button-icon {
        display: inline-block;
        width: 1.3125rem;
        height: 1.3125rem;
        margin-right: 10px;
    }
}
</style>
