<template>
    <BaseModule>
        <div class="module__row module__row_justify-center module__row_align-center">
            <slot></slot>
        </div>
    </BaseModule>
</template>
<script>
import BaseModule from '@/views/components/modules/BaseModule';

export default {
    components: {
        BaseModule,
    },
};
</script>

<style lang="scss">
.module {
    &__row {
        display: flex;

        &_justify-center {
            width: 100%;
            justify-content: center;
        }

        &_align-center {
            height: 100%;
            align-items: center;
        }
    }
}
</style>
