<template>
    <VTable>
        <VTableHeader>
            <template v-for="(column, i) in renderColumns">
                <VTableCol :key="i" class="table-col_space-nowrap table-col_no-select" v-bind="getAttributes(column)">{{
                    column.title
                }}</VTableCol>
            </template>
        </VTableHeader>

        <VTableRow :key="i" v-for="(renderValue, i) in renderValues">
            <template v-for="(column, k) in renderColumns">
                <VTableCol :key="k" v-bind="getAttributes(column)">{{ renderValue[column.name] }}</VTableCol>
            </template>
        </VTableRow>

        <VTableRow>
            <VTableCol v-bind="getAttributes(renderColumns[0])">Итого:</VTableCol>
            <template v-for="(column, k) in renderTotalColumns">
                <VTableCol :key="k" v-bind="getAttributes(column)">{{ renderResultValues[column.name] }}</VTableCol>
            </template>
        </VTableRow>
    </VTable>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import _sumBy from 'lodash/sumBy';

import { VTable, VTableBody, VTableHeader, VTableCol, VTableRow } from '../../../components';

@Component({
    components: { VTable, VTableBody, VTableHeader, VTableCol, VTableRow },
})
class ExecutiveTableRender extends Vue {
    /** @type {Array} **/
    @Prop({ type: Array, default: () => [] }) renderColumns;
    /** @type {Object} **/
    @Prop({ type: Object, default: () => ({}) }) renderValues;
    /** @type {Array} **/
    @Prop({ type: Array, default: () => [] }) renderTotalColumns;

    getAttributes(column) {
        const name = `col-min-${column.preferredColumnSize}`;

        return { [name]: true };
    }

    get renderResultValues() {
        const renderTotalValues = Object.values(this.renderValues);

        return this.renderTotalColumns.reduce((carry, item) => {
            carry[item.name] = _sumBy(renderTotalValues, item.name);

            return carry;
        }, {});
    }
}

export default ExecutiveTableRender;
</script>
