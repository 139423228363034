<template>
    <VueSelect :options="options" class="v-select-light" multiple placeholder="Квартал" v-model="value"></VueSelect>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import FilterYear from './FilterYear';

@Component({
    components: {
        FilterYear,
    },
})
class FilterQuarter extends Vue {
    value = [];
    options = [
        { label: '1 квартал', value: 1 },
        { label: '2 квартал', value: 2 },
        { label: '3 квартал', value: 3 },
        { label: '4 квартал', value: 4 },
    ];

    getValue() {
        return this.value.map((value) => value.value);
    }

    reset() {
        this.value = [];
    }
}

export default FilterQuarter;
</script>
