<template>
    <VModalWrapper :title="title" v-on="listeners" v-bind="attributes">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form class="active-form" @submit.prevent="handleSubmit(submit)">
                <ValidationProvider class="active-form__field" name="category" rules="required" v-slot="{ errors }">
                    <label class="active-form__label active-form__label_required" for="request-category"
                        >Категория</label
                    >

                    <VSelect
                        id="request-category"
                        name="category"
                        label="title"
                        class="active-form__control"
                        placeholder="Выберите категорию"
                        :class="{
                            error: errors[0],
                        }"
                        :options="categories"
                        @input="resetService"
                        v-model="category"
                    ></VSelect>

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider class="active-form__field" name="service" rules="required" v-slot="{ errors }">
                    <label class="active-form__label active-form__label_required" for="request-service">Сервис</label>

                    <VSelect
                        id="request-service"
                        name="service"
                        label="title"
                        class="active-form__control"
                        placeholder="Выберите сервис"
                        :class="{
                            error: errors[0],
                        }"
                        :options="category ? category.services : []"
                        v-model="service"
                    ></VSelect>

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider class="active-form__field" name="comment" v-slot="{ errors }">
                    <label class="active-form__label" for="request-comment">Комментарий</label>

                    <VContenteditable
                        id="request-comment"
                        name="comment"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="comment"
                    ></VContenteditable>

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <span class="active-form__field">
                    <InputCheckbox class="active-form__control" v-model="isKeepExecutor"
                        >Оставить текущего исполнителя</InputCheckbox
                    >
                </span>

                <div class="active-form__actions">
                    <VButton type="button" class="active-form__action button_green-light" @click="hide">
                        Отмена
                    </VButton>

                    <VButton type="submit" class="active-form__action button_green"> Изменить </VButton>
                </div>
            </form>
        </ValidationObserver>
    </VModalWrapper>
</template>
<script>
// Components
import VSelect from '../components/VSelect/VSelect.vue';
import VButton from '../components/VButton/VButton.vue';
import VModalWrapper from '../components/VModalWrapper/VModalWrapper.vue';
import VContenteditable from '../components/VContenteditable/VContenteditable.vue';
import InputCheckbox from '../components/VCheckbox/InputCheckbox.vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalNames from '../../js/enums/ModalNames';
import ModalMixin from '../../js/vue/mixins/ModalMixin';
import ErrorMessages from '../../js/enums/ErrorMessages';
import { namespace } from 'vuex-class';

const Workspace = namespace('workspace');

@Component({
    components: {
        VSelect,
        VButton,
        InputCheckbox,
        VModalWrapper,
        VContenteditable,

        ValidationObserver,
        ValidationProvider,
    },
})
class VRequestChangeServiceModal extends ModalMixin {
    @Prop({ type: String, default: ModalNames.REQUEST_CHANGE_SERVICE })
    name;
    @Prop({ type: String, default: 'Выберите категорию/сервис обращения' })
    defaultTitle;

    @Workspace.State categories;

    comment = null;
    service = null;
    category = null;
    isKeepExecutor = false;

    request = null;
    errorMessage = null;

    submit() {
        this.resolve({
            comment: this.comment,
            serviceId: this.service.id,
            categoryId: this.category.id,
            isChangeExecutor: !this.isKeepExecutor,
        });

        this.hide();
    }

    resetService() {
        this.service = null;
    }

    beforeOpen(event) {
        if (!event?.params?.request) {
            this.errorMessage = ErrorMessages.SOMETHING_WENT_WRONG;
        }

        this.request = event.params.request;

        if (this.request.categoryId && this.categories) {
            this.category = this.categories.find((category) => category.id == this.request.categoryId) ?? null;
        }

        if (this.request.serviceId && this.category) {
            this.service = this.category.services.find((service) => service.id == this.request.serviceId) ?? null;
        }
    }

    beforeClose() {
        this.comment = null;
        this.service = null;
        this.category = null;
        this.isKeepExecutor = false;

        this.request = null;
        this.errorMessage = null;
    }
}

export default VRequestChangeServiceModal;
</script>
