<template>
    <div class="header__col_profile">
        <div class="user-desk">
            <div class="user-desk__user-card" @click.stop="showDetails">
                <UserCard :account="account"></UserCard>
            </div>
            <div class="user-desk__user-details" v-show="toggleDetailsStatus">
                <UserDetails :account="account"></UserDetails>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

import UserCard from '@/views/components/VUser/UserCard.vue';
import UserDetails from '@/views/components/VUser/UserDetails.vue';

export default {
    components: {
        UserCard,
        UserDetails,
    },
    data: () => ({
        toggleDetailsStatus: false,
    }),
    methods: {
        toggleDetails() {
            this.toggleDetailsStatus = !this.toggleDetailsStatus;
        },

        showDetails() {
            if (!this.toggleDetailsStatus) this.toggleDetailsStatus = true;
        },

        hideDetails() {
            if (this.toggleDetailsStatus) this.toggleDetailsStatus = false;
        },
    },
    computed: {
        displayName() {
            return this.account ? this.account.displayName : '';
        },

        ...mapState('account', ['account']),
    },
    mounted() {
        document.addEventListener('click', this.hideDetails);
    },
    destroyed() {
        document.removeEventListener('click', this.hideDetails);
    },
};
</script>
<style lang="scss">
.header__col_profile {
    margin-left: auto;
}

.user-desk {
    display: flex;
    height: 100%;
    position: relative;
    align-items: center;

    &__user-card {
        width: 100%;
        cursor: pointer;
    }
}
</style>
