<template>
    <VueSelect :options="options" class="v-select-light" multiple placeholder="Год" v-model="value"></VueSelect>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
class FilterYear extends Vue {
    value = [];
    options = [];

    getValue() {
        return this.value;
    }

    reset() {
        this.value = [];
    }

    mounted() {
        this.options = [];
        const currentYear = new Date().getFullYear();

        for (let index = 2017; index <= currentYear; index++) {
            this.options.push(index);
        }
    }
}

export default FilterYear;
</script>
