import Vue from 'vue';
import { namespace } from 'vuex-class';
import Component from 'vue-class-component';
import AccountRoleType from '../../enums/AccountRoleType';

const Account = namespace('account');

@Component
class ContentGuard extends Vue {
    @Account.State((state) => state.account)
    $_account;
    @Account.State((state) =>
        state.account && state.account.roles ? state.account.roles.map((role) => role.role) : [],
    )
    $_roles;

    get $_isUser() {
        return this.$_roleExists(AccountRoleType.USER);
    }

    get $_isAdmin() {
        return this.$_roleExists(AccountRoleType.ADMIN);
    }

    get $_isExecutor() {
        return this.$_roleExists(AccountRoleType.EXECUTOR);
    }

    $_roleExists($role) {
        return this.$_roles.some((role) => role === $role);
    }

    $_userRoleExists(account, $role) {
        return account.roles.some((role) => role.role === $role);
    }
}

Vue.mixin(ContentGuard);
