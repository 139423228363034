<template>
    <VueSelect :options="options" class="v-select-light" multiple placeholder="Статус" v-model="value"></VueSelect>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import RequestStatusTitle from '@/js/enums/RequestStatusTitle';

@Component
class FilterStatus extends Vue {
    value = [];
    options = Object.keys(RequestStatusTitle).map((status) => ({
        value: parseInt(status),
        label: RequestStatusTitle[status],
    }));

    getValue() {
        return this.value.map((value) => value.value);
    }

    reset() {
        this.value = [];
    }
}

export default FilterStatus;
</script>
