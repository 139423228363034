<template>
    <VGridPageColumn sm-6 xs-12>
        <template v-if="isLoading">
            <VGridRow class="grid-row_fluid grid-row_center">
                <VSpinner></VSpinner>
            </VGridRow>
        </template>

        <template v-else>
            <ValidationObserver class="grid-row grid-row_h-100" v-slot="{ handleSubmit }">
                <form class="active-form" @submit.prevent="handleSubmit(onSubmit)">
                    <ValidationProvider
                        class="active-form__field"
                        name="description"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <label class="active-form__label active-form__label_required" for="request-description"
                            >Описание</label
                        >

                        <VContenteditable
                            id="request-description"
                            name="description"
                            v-model="comment"
                        ></VContenteditable>

                        <span class="active-form__error">
                            {{ errors[0] }}
                        </span>
                    </ValidationProvider>

                    <VGridScroll class="grid-scroll_hidden">
                        <VGridRow class="grid-row_without-padding">
                            <VGridCol>
                                <FileViewer upload v-model="files" style="max-height: 250px"></FileViewer>
                            </VGridCol>
                        </VGridRow>
                    </VGridScroll>

                    <VGridRow class="grid-row_without-padding">
                        <VButton type="button" class="button_green button_space-right" @click="toggleObserversLayer"
                            >Наблюдатели</VButton
                        >

                        <FileUploader class="button button_green" v-model="files">Загрузить</FileUploader>

                        <VGridSpacer></VGridSpacer>

                        <VButton class="button_green button_space-right">Создать обращение</VButton>
                    </VGridRow>
                </form>
            </ValidationObserver>
        </template>

        <VGridLayer class="grid-flex_padding-y" :value="showObserversLayer">
            <VGridScroll v-if="showObserversLayer">
                <VRequestObservers v-model="observers"></VRequestObservers>
            </VGridScroll>

            <VGridRow class="grid-row_padding-top grid-row_justify-end">
                <VButton @click="toggleObserversLayer" class="button_orange">Закрыть</VButton>
            </VGridRow>
        </VGridLayer>
    </VGridPageColumn>
</template>
<script>
// Components
import VSpinner from '../../components/VProgress/VSpinner.vue';
import VContenteditable from '../../components/VContenteditable/VContenteditable.vue';
import {
    VGridPageColumn,
    VGridCol,
    VGridRow,
    VGridScroll,
    VGridSpacer,
    VGridLayer,
    VRequestObservers,
} from '../../components';
import VButton from '@/views/components/VButton/VButton.vue';
import FileViewer from '@/views/components/FileViewer';
import FileUploader from '@/views/components/FileUploader';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { ResourceLayer } from './components';
import http from '../../../js/http';

@Component({
    components: {
        ResourceLayer,

        VContenteditable,
        VGridPageColumn,
        VGridCol,
        VGridRow,
        VSpinner,
        VGridScroll,
        VGridSpacer,
        VGridLayer,
        VRequestObservers,
        VButton,

        FileViewer,
        FileUploader,

        ValidationObserver,
        ValidationProvider,
    },
})
class RequestCreateView extends Vue {
    files = [];
    comment = null;
    errorMessage = '';
    service = {};
    observers = [];
    isLoading = true;
    showObserversLayer = false;

    onSubmit() {
        this.isLoading = true;

        const files = this.getFileIds();

        const data = {
            files,
            comment: this.comment,
            observers: this.observers,
            serviceId: this.service.id,
        };

        http.post('/requests', data)
            .then(({ data: request }) => {
                this.$router.push({
                    name: 'request',
                    params: {
                        method: 'outbox',
                        id: request.id,
                    },
                });
            })
            .catch((error) => {
                this.errorMessage = this.$errorMessage(error);
            })
            .finally(() => {
                this.files = [];
                this.comment = null;
                this.errorMessage = '';
                this.isLoading = false;
            });
    }

    toggleObserversLayer() {
        this.showObserversLayer = !this.showObserversLayer;
    }

    getFileIds() {
        return this.files.filter((file) => file.id && file.id !== -1).map((file) => file.id);
    }

    get serviceId() {
        return this.$route.params.serviceId;
    }

    @Watch('serviceId')
    fetchService() {
        this.isLoading = true;
        http.get(`/services/${this.serviceId}?with=observers`)
            .then(({ data: service }) => {
                this.service = service;
                this.observers = service.observers.map((observer) => observer.id);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    created() {
        this.fetchService();
    }
}

export default RequestCreateView;
</script>
