<template>
    <input :placeholder="placeholder" :type="type" :value="value" @input="input" class="input" />
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: 'text',
        },
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
    },
    data: () => ({}),
    methods: {
        input(event) {
            this.$emit('input', event.target.value);
        },
    },
};
</script>
<style lang="scss">
.input {
    width: 100%;
    padding: 0 0.5rem;
    font: 400 1rem Roboto;
}
</style>
