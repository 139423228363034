<template>
    <div class="app-layout">
        <div :data-theme="theme" class="app-layout__body">
            <div class="app-layout__wrapper">
                <VAppHeader class="app-layout__row app-layout__row_header" v-if="!hideHeaderBar">
                    <VAppHeaderLogo class="header__col"></VAppHeaderLogo>
                    <VAppHeaderMenu class="header__col"></VAppHeaderMenu>
                    <VAppHeaderProfile class="header__col"></VAppHeaderProfile>
                </VAppHeader>

                <VAppMain class="app-layout__row app-layout__row_main">
                    <slot></slot>
                </VAppMain>
            </div>

            <VSnowfall></VSnowfall>
        </div>
    </div>
</template>
<script>
import './VApp.scss';

import Vue from 'vue';
import Component from 'vue-class-component';

import VAppMain from './VAppMain.vue';
import VAppHeader from './VAppHeader.vue';
import VAppSidebar from './VAppSidebar.vue';
import VAppHeaderLogo from './VAppHeaderLogo.vue';
import VAppHeaderMenu from './VAppHeaderMenu.vue';
import VAppHeaderProfile from './VAppHeaderProfile.vue';
import VSnowfall from '../VFun/VSnowfall.vue';

@Component({
    components: {
        VSnowfall,
        VAppMain,
        VAppHeader,
        VAppSidebar,
        VAppHeaderLogo,
        VAppHeaderMenu,
        VAppHeaderProfile,
    },
})
class AppLayout extends Vue {
    date = new Date();
    theme = 'light';

    toggleTheme() {
        this.theme = this.theme === 'light' ? 'dark' : 'light';
    }

    get year() {
        return this.date.getFullYear();
    }

    get hideHeaderBar() {
        return this.$route.meta.hideHeaderBar === true;
    }
}

export default AppLayout;
</script>
