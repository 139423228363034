import types from './RequestHistoryActionType';

export default class RequestHistoryActionColor {
    static [types.CLOSED] = '#484848';
    static [types.DENIED] = '#eb4a14';
    static [types.PAUSED] = '#eb4a14';
    static [types.REWORK] = '#484848';
    static [types.WORKUP] = '#12B23F';
    static [types.FINISH] = '#12B23F';
    static [types.CHANGE_EXECUTOR] = '#484848';
    static [types.CHANGE_SECURITY_GROUP] = '#484848';
    static [types.CREATED] = '#484848';
    static [types.COMMENTED] = '#484848';
    static [types.SYSTEM_CLOSED] = '#484848';
}
