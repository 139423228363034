import types from './RequestStateType';

export default class RequestStateColor {
    static [types.GOOD] = '#1ca75e';
    static [types.NORMAL] = '#484848';
    static [types.WARN] = '#f52222';
    static [types.FATAL] = '#f52222';

    static color(request) {
        return this[types.type(request)];
    }
}
