import FilterYear from './FilterYear.vue';
import FilterMonth from './FilterMonth.vue';
import FilterPeriod from './FilterPeriod.vue';
import FilterStatus from './FilterStatus.vue';
import FilterService from './FilterService.vue';
import FilterAuthor from './FilterAuthor.vue';
import FilterExecutor from './FilterExecutor.vue';
import FilterQuarter from './FilterQuarter.vue';
import FilterDay from './FilterDay.vue';
import FilterCategory from './FilterCategory.vue';
import FilterCity from './FilterCity.vue';
import FilterExpired from './FilterExpired.vue';

export default {
    FilterYear,
    FilterMonth,
    FilterPeriod,
    FilterStatus,
    FilterService,
    FilterAuthor,
    FilterExecutor,
    FilterExpired,
    FilterQuarter,
    FilterDay,
    FilterCategory,
    FilterCity,
};
