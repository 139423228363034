import Vue from 'vue';
import { errorMessage } from '../error';

const errors = {
    install(_Vue) {
        _Vue.prototype.$showError = function (error) {
            this.$bus.emit('Application:ShowError', error);
        };
        _Vue.prototype.$errorMessage = function (error, defaultMessage) {
            return errorMessage(error, defaultMessage);
        };
    },
};

Vue.use(errors);
