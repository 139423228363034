<template>
    <span class="radial-progress">
        <svg viewBox="0 0 34 34" class="radial-progress__progress">
            <circle class="radial-progress__meter" cx="17" cy="17" r="16" fill="transparent"></circle>

            <circle
                class="radial-progress__value"
                cx="17"
                cy="17"
                r="16"
                fill="transparent"
                stroke-dashoffset="0"
                :stroke-dasharray="dasharray"
                v-if="completed > 0"
            ></circle>
        </svg>
    </span>
</template>
<script>
import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';

@Component
class VProgressRadial extends Vue {
    @Prop({ type: Number, default: 100 })
    total;

    @Prop({ type: Number, default: 0 })
    value;

    @Prop({ type: Number, default: 0 })
    progress;

    get dasharray() {
        return `${this.completed} ${100 - this.completed}`;
    }

    get completed() {
        // If progress is exists then return it.
        if (this.progress) {
            return this.progress;
        }

        // If value more than total then return max value.
        if (this.value > this.total) {
            return 100;
        }

        if (this.total === 0) {
            return 0;
        }

        return Math.round((this.value * 100) / this.total);
    }
}

export default VProgressRadial;
</script>
<style lang="scss">
.radial-progress {
    display: block;
    position: relative;
    width: 2.6875rem;
    height: 2.6875rem;

    &__progress {
        transform: rotate(-90deg);
        display: block;
        width: 100%;
        height: 100%;
    }

    &__meter {
        stroke-width: 1;
        fill: none;
        stroke: #e8e8e8;
    }

    &__value {
        stroke-width: 2;
        fill: none !important;
        stroke: var(--color-green);
        stroke-linecap: round;
    }
}
</style>
