<template>
    <ModuleContentCenter>
        <div class="error-page__code">{{ code }}</div>
        <div class="error-page__title" v-html="title"></div>
    </ModuleContentCenter>
</template>
<script>
/* eslint-disable */
import { Vue, Component } from 'vue-property-decorator';

import ModuleContentCenter from '@/views/modules/default/ModuleContentCenter';

@Component({
    components: {
        ModuleContentCenter,
    },
})
class ErrorPage extends Vue {
    get code() {
        return this.$route.meta.code;
    }

    get title() {
        return this.$route.meta.title;
    }
}

export default ErrorPage;
</script>
<style lang="scss">
.error-page {
    &__code {
        font-size: 26px;
        text-align: center;
        padding: 0 15px;
        border-right: 2px solid #636b6faa;
        color: #636b6f;

        font: 400 4rem Roboto;
    }

    &__title {
        padding: 10px;
        color: #636b6f;

        font: 300 1.3rem Roboto;
    }
}
</style>
