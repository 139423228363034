import './VGrid.scss';
import Vue from 'vue';

const name = 'grid-page-column';

export default Vue.extend({
    name,

    functional: true,

    props: {
        id: {
            type: String,
            default: '',
        },
        tag: {
            type: String,
            default: 'div',
        },
    },

    render(h, { props, data, children }) {
        const attrs = data.attrs;
        const inheritStaticClass = data.staticClass || '';

        data.staticClass = name;

        if (attrs) {
            data.attrs = {};

            for (const key in attrs) {
                if (key === 'slot') {
                    continue;
                }

                const value = attrs[key];

                if (key.startsWith('data-')) {
                    data.attrs[key] = value;
                    continue;
                }

                data.staticClass += ` ${key}`;
            }
        }

        if (props.id) {
            data.domProps = data.domProps || {};
            data.domProps.id = props.id;
        }

        const contentData = {
            staticClass: `grid-page-column__context ${inheritStaticClass || ''}`.trim(),
        };
        const separatorData = {
            staticClass: 'grid-page-column__separator',
        };

        return h(props.tag, data, [h('div', contentData, children), h('div', separatorData)]);
    },
});
