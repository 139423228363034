<template>
    <div :class="$style['request__wrapper']">
        <RouterLink :class="$style['request']" :style="stateColor" :to="routerRequestLink" feed-request>
            <div :class="$style['request__row']">
                <span :class="$style['request__text']"
                    >Обращение № {{ request.id }}, до {{ $_toLocalDate(request.deadline) }}</span
                >
                <span :class="[$style['request__text'], $style['request__text_right']]">
                    <span>[</span>
                    <span :style="{ color: statusColor(request.status) }">
                        {{ statusTitle(request.status) }}
                    </span>
                    <span>]</span>

                    <template v-if="isObserver">
                        <span>[</span>
                        <span style="color: #0db5b5">Наблюдатель</span>
                        <span>]</span>
                    </template>
                </span>
            </div>
            <p :class="[$style['request__comment']]">
                {{ clearHtml(comment) }}
            </p>
            <div :class="$style['request__row']">
                <span :class="$style['request__text']">Создал обращение: {{ request.author.displayName }}</span>
                <span :class="[$style['request__text'], $style['request__text_right']]">{{ request.created_at }}</span>
            </div>
        </RouterLink>
    </div>
</template>
<script>
import last from 'lodash/last';
import titles from '@/js/enums/RequestStatusTitle';
import colors from '@/js/enums/RequestStatusColor';
import RequestStateColor from '@/js/enums/RequestStateColor';

export default {
    props: {
        request: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {
        clearHtml(comment) {
            return comment.replace(/<br \/>|<span class='chat-text-color-blue'>|<\/span>/g, ' ');
        },
    },
    computed: {
        isObserver() {
            return this.request.observers.find((observer) => observer.id === this.$_account.id);
        },
        comment() {
            if (!this.lastComment) return '';

            if (this.lastComment.text)
                return this.lastComment.text.length > 100
                    ? `${this.lastComment.text.substring(0, 100).trim(' ')}...`
                    : this.lastComment.text;

            if (this.lastComment.files && this.lastComment.files.length)
                return `Документ: ${last(this.lastComment.files).name}`;

            return '';
        },
        lastComment() {
            return last(this.request.comments);
        },
        statusTitle() {
            return (status) => titles[status];
        },
        statusColor() {
            return (status) => colors[status];
        },
        stateColor() {
            return `border-left: 6px solid ${RequestStateColor.color(this.request)}`;
        },
        routerRequestLink() {
            return {
                name: 'request',
                params: {
                    id: this.request.id,
                    method: this.$route.params.method,
                },
            };
        },
    },
};
</script>
<style lang="scss" module>
:global(.router-link-active[feed-request]) {
    background-color: rgba(44, 44, 44, 0.12);
}

.green1 {
    color: #1ca75e;
}

.request {
    display: block;
    width: 100%;
    padding: 0 10px;
    color: inherit;
    transition: all 0.3s;
    text-decoration: none;
    border-left: 6px solid rgba(44, 44, 44, 0.12);

    //////////////////////////////////////////////////////////////
    &__wrapper {
        width: 100%;
        padding-bottom: 2px;
        margin-bottom: 1px;
        background-size: 7px 1px;
        background-repeat: repeat-x;
        background-position: bottom;
        background-image: linear-gradient(90deg, #666 15%, hsla(0, 0%, 100%, 0) 0);
    }
    //////////////////////////////////////////////////////////////

    &__row {
        display: flex;
        flex-wrap: wrap;
        padding: 8px 0;

        &_preview {
            padding: 0 0 0 4px;
        }
    }

    &__text {
        font: 400 0.83rem Roboto;

        &_italic {
            color: #222;
            font: italic 0.9rem Roboto;
        }

        &_right {
            padding-right: 6px;
            margin-left: auto;
        }

        &_padding {
            padding: 0 6px;
        }
    }

    &__comment {
        margin: 4px 0;
        padding-left: 4px;
        color: #222;
        word-wrap: break-word;
        font: italic 0.9rem Roboto;
    }

    &_green {
        border-left-color: rgb(28, 167, 94);
    }

    &_red {
        border-left-color: rgb(240, 93, 90);
    }

    &_orange {
        border-left-color: rgb(241, 144, 91);
    }

    &:hover {
        transition: all 0.3s;
        background-color: rgba(44, 44, 44, 0.12);
    }
}
</style>
