import AdminPage from '../views/pages/Admin/AdminPage.vue';
import AdminPageUsers from '../views/pages/Admin/AdminPageUsers.vue';
import AdminPageCities from '../views/pages/Admin/AdminPageCities.vue';
import AdminPageServices from '../views/pages/Admin/AdminPageServices.vue';
import AdminPageCategories from '../views/pages/Admin/AdminPageCategories.vue';
import AdminPageSecurityGroups from '../views/pages/Admin/AdminPageSecurityGroups.vue';
import oidc from '../vue-oidc-client';

export default [
    {
        name: 'admin-panel',
        path: '/admin',
        component: AdminPage,
        children: [
            {
                name: 'admin-panel.users',
                path: 'users',
                component: AdminPageUsers,
            },
            {
                name: 'admin-panel.cities',
                path: 'cities',
                component: AdminPageCities,
            },
            {
                name: 'admin-panel.services',
                path: 'services',
                component: AdminPageServices,
            },
            {
                name: 'admin-panel.categories',
                path: 'categories',
                component: AdminPageCategories,
            },
            {
                name: 'admin-panel.security-groups',
                path: 'security-groups',
                component: AdminPageSecurityGroups,
            },
        ],
        meta: {
            authName: oidc.authName,
        },
    },
];
