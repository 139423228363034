var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"request-card"},[_c('div',{staticClass:"request-card__column request-card__column_left"},[_c('div',{staticClass:"request-card__row"},[_c('span',{staticClass:"request-card__row-key"},[_vm._v("Статус")]),_c('span',{staticClass:"request-card__row-value",style:({ color: _vm.statusColor })},[_vm._v(_vm._s(_vm.statusTitle))])]),_c('div',{staticClass:"request-card__row"},[_c('span',{staticClass:"request-card__row-key"},[_vm._v("Автор")]),_c('div',{staticClass:"request-card__row-value request-card__row-value_author"},[_c('UserCardWithPopover',{attrs:{"short-name":"","account":_vm.request.author}})],1)]),_c('div',{staticClass:"request-card__row",class:{
                'request-card__row_acting': _vm.$_isExecutor,
            },on:{"click":_vm.changeExecutor}},[_c('span',{staticClass:"request-card__row-key"},[_vm._v("Исполнитель")]),_c('div',{staticClass:"request-card__row-value request-card__row-value_executor"},[(_vm.request.executor)?[_c('UserCardWithPopover',{attrs:{"short-name":"","account":_vm.request.executor}})]:(_vm.request.securityGroup)?[(_vm.request.securityGroup.accounts)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.executorNames),expression:"executorNames"}],staticClass:"request-executors"},[_c('span',{staticClass:"request-executors__group-name"},[_vm._v(" "+_vm._s(_vm.request.securityGroup.name)+" ")])]):_vm._e()]:_vm._e()],2)])]),_c('div',{staticClass:"request-card__column request-card__column_separator"}),_c('div',{staticClass:"request-card__column request-card__column_right"},[_c('div',{staticClass:"request-card__row",class:{
                'request-card__row_acting': _vm.$_isExecutor,
            },on:{"click":_vm.changeCategory}},[_c('span',{staticClass:"request-card__row-key"},[_vm._v("Категория")]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.request.category.title),expression:"request.category.title"}],staticClass:"request-card__row-value"},[_vm._v(_vm._s(_vm.request.category.title))])]),_c('div',{staticClass:"request-card__row",class:{
                'request-card__row_acting': _vm.$_isExecutor,
            },on:{"click":_vm.changeService}},[_c('span',{staticClass:"request-card__row-key"},[_vm._v("Сервис")]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.request.service.title),expression:"request.service.title"}],staticClass:"request-card__row-value"},[_vm._v(_vm._s(_vm.request.service.title))])]),_c('div',{staticClass:"request-card__row"},[_c('span',{staticClass:"request-card__row-key"},[_vm._v("Срок")]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$_toLocalDateHistory(_vm.request.deadline)),expression:"$_toLocalDateHistory(request.deadline)"}],staticClass:"request-card__row-value",style:({ color: _vm.stateColor })},[(_vm.isRequestPaused)?[_vm._v(" Приостановлено ")]:(_vm.isRequestOverdue)?[_vm._v(" Просрочено ")]:(_vm.isInWork)?[_vm._v(" "+_vm._s(_vm.$_toLocalRelativeDate(_vm.request.deadline))+" ")]:_vm._e()],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }