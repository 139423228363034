var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VModalWrapper',_vm._g(_vm._b({attrs:{"title":_vm.title,"is-loading":_vm.isLoading}},'VModalWrapper',_vm.attributes,false),_vm.listeners),[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{staticClass:"active-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"displayName","rules":"required|max:256"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"user-displayName"}},[_vm._v("Имя")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"user-displayName","name":"displayName"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.user.displayName),callback:function ($$v) {_vm.$set(_vm.user, "displayName", $$v)},expression:"user.displayName"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"roles","rules":"required|max:128"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"user-roles"}},[_vm._v("Роли")]),_c('VSelect',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"user-roles","name":"roles","multiple":"","options":_vm.roles},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.user.roles),callback:function ($$v) {_vm.$set(_vm.user, "roles", $$v)},expression:"user.roles"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"email","rules":"required|email|max:256"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"user-email"}},[_vm._v("E-mail")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"user-email","name":"email"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"company","rules":"max:128"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"user-company"}},[_vm._v("Компания")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"user-company","name":"company"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.user.company),callback:function ($$v) {_vm.$set(_vm.user, "company", $$v)},expression:"user.company"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"department","rules":"max:128"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"user-department"}},[_vm._v("Отдел")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"user-department","name":"department"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.user.department),callback:function ($$v) {_vm.$set(_vm.user, "department", $$v)},expression:"user.department"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"title","rules":"max:256"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"user-title"}},[_vm._v("Должность")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"user-title","name":"title"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.user.title),callback:function ($$v) {_vm.$set(_vm.user, "title", $$v)},expression:"user.title"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"telephone","rules":"max:256"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"user-telephone"}},[_vm._v("Рабочий тел.")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"user-telephone","name":"telephone"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.user.telephone),callback:function ($$v) {_vm.$set(_vm.user, "telephone", $$v)},expression:"user.telephone"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"mobile","rules":"max:24"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"user-mobile"}},[_vm._v("Мобильный тел.")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"user-mobile","name":"mobile"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.user.mobile),callback:function ($$v) {_vm.$set(_vm.user, "mobile", $$v)},expression:"user.mobile"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"location","rules":"required|max:128"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"user-location"}},[_vm._v("Город")]),_c('VSelect',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"user-location","name":"location","label":"name","options":_vm.cities,"reduce":(x) => x.name},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.user.location),callback:function ($$v) {_vm.$set(_vm.user, "location", $$v)},expression:"user.location"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('div',{staticClass:"active-form__field"},[_c('label',{staticClass:"active-form__label",attrs:{"for":"user-location"}},[_vm._v("Часовой пояс")]),_c('VInput',{staticClass:"active-form__control",attrs:{"id":"user-location","disabled":""},model:{value:(_vm.user.timezone),callback:function ($$v) {_vm.$set(_vm.user, "timezone", $$v)},expression:"user.timezone"}})],1),_c('div',{staticClass:"active-form__actions"},[_c('VButton',{staticClass:"active-form__action button_green-light",attrs:{"type":"button"},on:{"click":_vm.hide}},[_vm._v(" Отмена ")]),_c('VButton',{staticClass:"active-form__action button_green",attrs:{"type":"submit"}},[_vm._v(" Сохранить ")])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }