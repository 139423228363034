<template>
    <VGridFlex class="grid-flex_column">
        <VGridRow>
            <VGridCol>
                <label>Наблюдатели:</label>
                <VueSelect
                    :options="options"
                    :reduce="(account) => account.value"
                    class="v-select-light"
                    multiple
                    placeholder="Выберите наблюдателя"
                    :value="value"
                    @input="onInputValue"
                ></VueSelect>
            </VGridCol>
        </VGridRow>

        <VGridRow class="grid-row_without-padding grid-row_padding-y">
            <VGridCol>
                <template v-for="observer in observers">
                    <VRequestObserver :key="observer.id" :observer="observer"></VRequestObserver>
                </template>
            </VGridCol>
        </VGridRow>
    </VGridFlex>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';

import VSpinner from '../VProgress/VSpinner.vue';
import { VGridCol, VGridRow, VGridFlex, VGridScroll } from '../index';
import VRequestObserver from './VRequestObserver.vue';
import { namespace } from 'vuex-class';

const Workspace = namespace('workspace');

/**
 * @property {Array} value
 */
@Component({
    components: {
        VSpinner,
        VGridCol,
        VGridRow,
        VGridFlex,
        VGridScroll,
        VRequestObserver,
    },
})
class VRequestObservers extends Vue {
    /** @type {Number[]} **/
    @Prop({ type: Array, default: () => [] }) value;

    /** @type {Account[]} **/
    @Workspace.State accounts;

    @Emit('input')
    onInputValue(value) {
        return value;
    }

    /** @type {{ value: Number, label: String }[]} **/
    get options() {
        return this.accounts
            .filter((account) => account.enabled)
            .map((account) => ({
                value: account.id,
                label: account.displayName,
            }));
    }

    /** @type {Account[]} **/
    get observers() {
        return this.accounts.filter((account) => this.value.some((value) => value == account.id));
    }
}

export default VRequestObservers;
</script>
