import axios from 'axios';
import oidc from '../vue-oidc-client';

const BASE_URL = process.env.VUE_APP_API_URL;

function createAxiosInstance(baseURL = BASE_URL) {
    const instance = axios.create({
        baseURL,
    });

    // instance.defaults.headers.common['Accept'] = 'application/json';
    instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    return instance;
}

function useJwtAuthorization(instance) {
    instance.interceptors.request.use(
        (request) => {
            request.headers.authorization = 'Bearer ' + oidc.accessToken;

            return request;
        },
        (error) => Promise.reject(error),
    );

    instance.interceptors.response.use(undefined, async (error) => {
        try {
            if (error && error.response && error.response.status === 401) {
                oidc.signIn();
            }

            return Promise.reject(error);
        } catch (error) {
            window.location = '/';
        }
    });

    return instance;
}

function createApiInstance(BASE_URL) {
    const client = createAxiosInstance(BASE_URL);

    useJwtAuthorization(client);

    return client;
}

export { createApiInstance, createAxiosInstance, useJwtAuthorization };
export default createApiInstance(BASE_URL);
