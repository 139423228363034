<template>
    <div class="form__checkbox">
        <input
            :checked="value"
            :class="classOptions"
            :id="id"
            :name="name"
            :style="styleOptions"
            :type="type"
            @change.stop="onChenge"
            class="form__input form__input_checkbox"
        />
        <label :for="id" class="form__label form__label_checkbox">
            <slot></slot>
        </label>
    </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';

import { generateId, generateName } from '@/js/healper';

@Component
class InputCheckbox extends Vue {
    @Prop({
        type: Object,
        default: () => ({
            id: null,
            name: null,
            type: 'checkbox',
            class: '',
            style: '',
        }),
    })
    options;

    @Prop({ type: Boolean, default: false }) value;

    id = generateId();
    name = generateName();
    type = 'checkbox';
    classOptions = '';
    styleOptions = '';

    @Emit('input')
    onChenge() {
        return !this.value;
    }

    mounted() {
        if (this.options.id) this.id = this.options.id;
        if (this.options.name) this.name = this.options.name;

        this.type = this.options.type;
        this.classOptions = this.options.class;
        this.styleOptions = this.options.style;
    }
}

export default InputCheckbox;
</script>
<style lang="scss">
.form {
    &__checkbox {
        display: flex;
    }

    &__input {
        &_checkbox {
            padding-right: 3px;
            cursor: pointer;
        }
    }

    &__label {
        &_checkbox {
            font: 400 0.9rem Roboto;
            cursor: pointer;
        }
    }
}
</style>
