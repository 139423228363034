import style from './styles/BaseModule.module.scss';

export default {
    functional: true,
    render: (h, { children, data }) => (
        <div class={style.module} {...data}>
            <div class={[style.module__row, style.module__row_window]}>{children}</div>
        </div>
    ),
};
