<template>
    <FlatPickr
        :config="config"
        class="input input_light v-filter-day__col v-filter-day__col_70"
        placeholder="День"
        required
        v-model="value"
    ></FlatPickr>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Russian } from 'flatpickr/dist/l10n/ru.js';

@Component
class FilterDay extends Vue {
    value = '';
    config = {
        mode: 'multiple',
        weekNumbers: true,
        dateFormat: 'd.m.Y',
        locale: Russian,
    };

    getValue() {
        return this.value ? this.value.split(', ') : false;
    }

    reset() {
        this.value = '';
    }
}

export default FilterDay;
</script>
