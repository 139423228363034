<template>
    <button class="button" :class="{ 'button-progress': progress }" v-on="$listeners">
        <slot name="icon">
            <i :class="icon" class="button__old-icon" v-if="icon"></i>
        </slot>

        <!-- <span class="button_content"> -->
        <slot />
        <!-- </span> -->

        <div class="button-progress__progress" v-if="progress">
            <svg class="button-progress__progress-svg" viewBox="25 25 50 50">
                <circle cx="50" cy="50" r="20" />
            </svg>
        </div>
    </button>
</template>
<script>
export default {
    props: {
        icon: {
            type: [String, Object],
            default: '',
        },
        progress: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
<style lang="scss">
.button {
    --button-green-color: var(--text-white);
    --button-green-background: var(--color-green);
    --button-green-background-hover: #15754a;
    --button-green-background-active: var(--color-green);

    --button-green-light-color: var(--color-green);
    --button-green-light-border: var(--color-green);
    --button-green-light-background-hover: #f3fcf8;
    --button-green-light-background-active: #ddf0e8;

    --button-orange-color: var(--text-white);
    --button-orange-background: var(--color-orange);
    --button-orange-background-hover: #e26420;
    --button-orange-background-active: var(--color-orange);
}

.button {
    display: flex;
    margin: 0;
    padding: 12px 24px;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    border-radius: 8px;
    line-height: 1rem;
    box-sizing: border-box;
    box-shadow: 0px 1px 10px rgba(88, 88, 88, 0.15);
    background: none;
    color: var(--text-black);
    cursor: pointer;
    font: 400 0.875rem Roboto;
    transition: all 0.2s;

    &_fluid {
        width: 100%;
    }

    &_small {
        padding: 5px 15px;
        border-radius: 4px;
        line-height: 0.875rem;
        font-size: 0.75rem;
    }

    &_small-text {
        font-size: 0.8125rem;
        line-height: 0.9375rem;
    }

    &_no-shadow {
        box-shadow: none;
    }

    &_square {
        padding: 10px;
        max-height: 38px;
    }

    &_left {
        justify-content: flex-start;
    }

    &_right {
        justify-content: flex-end;
    }

    &_without-padding {
        padding: 0;
    }

    &_padding-x {
        padding-left: 15px;
        padding-right: 15px;
    }

    &_padding-y {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &_hover {
        &:hover {
            background-color: rgba(44, 44, 44, 0.12);
        }
    }

    &_green {
        color: var(--button-green-color);
        background: var(--button-green-background);

        & .button__icon {
            fill: var(--button-green-color);
        }

        &:hover,
        &:focus-visible {
            background: var(--button-green-background-hover);
        }

        &:active {
            background: var(--button-green-background-active);
        }
    }

    &_green-light {
        box-shadow: none;
        color: var(--button-green-light-color);
        border: 1px solid var(--button-green-light-border);

        & .button__icon {
            fill: var(--button-green-light-color);
        }

        &:hover,
        &:focus-visible {
            background: var(--button-green-light-background-hover);
        }

        &:active {
            background: var(--button-green-light-background-active);
        }
    }

    &_orange {
        color: var(--button-orange-color);
        background: var(--button-orange-background);

        & .button__icon {
            fill: var(--button-green-color);
        }

        &:hover,
        &:focus-visible {
            background: var(--button-orange-background-hover);
        }

        &:active {
            background: var(--button-orange-background-active);
        }
    }

    &_space-left {
        margin-left: 5px;
    }

    &_space-right {
        margin-right: 5px;
    }

    &__icon {
        height: 0.875em;

        &_left {
            margin-right: 8px;
        }

        &_right {
            margin-left: 8px;
        }
    }

    &__old-icon {
        display: block;
        width: auto;
        height: auto;
        margin-right: 8px;
    }
}

.button-progress {
    position: relative;

    &_active {
        cursor: default;
    }

    &__progress {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 8px;
        background: rgba(44, 44, 44, 0.4);

        &-svg {
            height: 100%;
            width: 25px;
            transform-origin: center;
            animation: rotate 2s linear infinite;

            circle {
                fill: none;
                stroke: var(--color-white);
                stroke-width: 4;
                stroke-dasharray: 1, 200;
                stroke-dashoffset: 0;
                stroke-linecap: round;
                animation: dash 1.5s ease-in-out infinite;
            }
        }
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: -10px;
    }
    50% {
        stroke-dasharray: 100, 200;
        stroke-dashoffset: -25px;
    }
    100% {
        stroke-dashoffset: -125px;
    }
}
</style>
