export default {
    default: {
        is: 'ControlTextarea',
        data: {
            required: true,
            value: '',
            label: 'Описание:',
            placeholder: 'Описание',
        },
        meta: {
            icon: 'fal fa-font',
            title: 'Описание',
        },
    },
    builder: {
        controls: [
            {
                is: 'ControlInputName',
                data: {
                    required: true,
                    label: 'Фио:',
                    value: {
                        name: '',
                        surname: '',
                        patronymic: '',
                    },
                    placeholder: {
                        name: 'Имя',
                        surname: 'Фамилия',
                        patronymic: 'Отчество',
                    },
                },
                meta: {
                    icon: 'fal fa-font',
                    title: 'Фио',
                },
            },
            {
                is: 'ControlEmail',
                data: {
                    required: true,
                    value: '',
                    label: 'Почта:',
                    placeholder: 'Почта',
                },
                meta: {
                    icon: 'fal fa-font',
                    title: 'Почта',
                },
            },
            {
                is: 'ControlTextarea',
                data: {
                    required: true,
                    value: '',
                    label: 'Описание:',
                    placeholder: 'Описание',
                },
                meta: {
                    icon: 'fal fa-font',
                    title: 'Описание',
                },
            },
            {
                is: 'ControlMobile',
                data: {
                    required: true,
                    value: '',
                    label: 'Мобильный телефон:',
                    placeholder: '+7 (123) 456-78-90',
                },
                meta: {
                    icon: 'fal fa-font',
                    title: 'Мобильный телефон',
                },
            },
            {
                is: 'ControlPhone',
                data: {
                    required: true,
                    value: '',
                    label: 'Внутренний телефон:',
                    placeholder: '9000',
                },
                meta: {
                    icon: 'fal fa-font',
                    title: 'Внутренний телефон',
                },
            },
        ],
    },
};
