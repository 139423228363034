import http from '../../js/http';

const URI_REQUEST_FILTER_SETTINGS = '/request-filter-settings';

export default {
    BOOT({ commit }, { account }) {
        return account ? commit('LOGIN', account) : commit('LOGOUT');
    },

    CREATE_REQUEST_FILTER_SETTINGS({ commit }, requestFilterSettings) {
        return http
            .post(URI_REQUEST_FILTER_SETTINGS, requestFilterSettings)
            .then(({ data: requestFilterSettings }) => commit('CREATE_REQUEST_FILTER_SETTINGS', requestFilterSettings));
    },

    UPDATE_REQUEST_FILTER_SETTINGS({ commit }, { requestFilterSettingsId, requestFilterSettings }) {
        return http
            .patch(URI_REQUEST_FILTER_SETTINGS + `/${requestFilterSettingsId}`, requestFilterSettings)
            .then(() => commit('UPDATE_REQUEST_FILTER_SETTINGS', requestFilterSettings));
    },
};
