import ErrorPage from '../views/pages/ErrorPage';
import oidc from '../vue-oidc-client';

export default [
    {
        name: 'error-401',
        path: '/error/401',
        meta: {
            code: 401,
            authName: oidc.authName,
            title: 'Вы не авторизированы в системе.',
        },
        component: ErrorPage,
    },
    {
        name: 'error-403',
        path: '/error/403',
        meta: {
            code: 403,
            authName: oidc.authName,
            title: 'Отказано в доступе. <br /> Обратитесь к администратору системы.',
        },
        component: ErrorPage,
    },
    {
        name: 'error-404',
        path: '/error/404',
        meta: {
            code: 404,
            authName: oidc.authName,
            title: 'Запрашиваемая страница <br/> не найдена.',
        },
        component: ErrorPage,
    },
    {
        name: 'error-500',
        path: '/error/500',
        meta: {
            code: 500,
            authName: oidc.authName,
            title: 'На стороне сервера произошла ошибка, <br /> <a href="/" style="color: var(--color-green);">Перезагрузить страницу</a>.',
        },
        component: ErrorPage,
    },
];
