<template>
    <VueSelect :options="options" class="v-select-light" multiple placeholder="Месяц" v-model="value"></VueSelect>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
class FilterMonth extends Vue {
    value = [];
    options = [
        { label: 'Январь', value: 1 },
        { label: 'Февраль', value: 2 },
        { label: 'Март', value: 3 },
        { label: 'Апрель', value: 4 },
        { label: 'Май', value: 5 },
        { label: 'Июнь', value: 6 },
        { label: 'Июль', value: 7 },
        { label: 'Август', value: 8 },
        { label: 'Сентябрь', value: 9 },
        { label: 'Октябрь', value: 10 },
        { label: 'Ноябрь', value: 11 },
        { label: 'Декабрь', value: 12 },
    ];

    getValue() {
        return this.value.map((value) => value.value);
    }

    reset() {
        this.value = [];
    }
}

export default FilterMonth;
</script>
