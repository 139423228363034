<template>
    <VueSelect
        :options="options"
        @input="onChange"
        class="v-select-light"
        multiple
        placeholder="Категория"
        v-model="value"
    ></VueSelect>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';

const Workspace = namespace('workspace');

@Component
class FilterCategory extends Vue {
    @Workspace.State((state) => state.categories) storeCategories;

    value = [];
    groups = [];

    getValue() {
        return this.value.map((value) => value.value);
    }

    reset() {
        this.value = [];
    }

    onChange() {
        this.$bus.emit('filter-category:changed', this.value);
    }

    onGroupChange(groups) {
        this.groups = groups;

        this.value = this.value.filter((category) => this.groups.some((group) => group.value === category.groupId));
        this.$bus.emit('filter-category:changed', this.value);
    }

    get options() {
        let options = this.storeCategories;

        if (this.groups.length) {
            options = options.filter((category) => this.groups.some((group) => group.value === category.groupId));
        }

        return options.map((category) => ({
            value: category.id,
            label: category.title,
            groupId: category.groupId,
        }));
    }

    created() {
        this.$bus.on('filter-group:changed', this.onGroupChange);
    }

    beforeDestroy() {
        this.$bus.off('filter-group:changed', this.onGroupChange);
    }
}

export default FilterCategory;
</script>
