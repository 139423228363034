<template>
    <div class="pagination">
        <div class="pagination__wrapper">
            <button @click="$emit('prev')" class="button button_orange pagination__item pagination__item_action">
                <ArrowLeft :size="22"></ArrowLeft>
            </button>

            <button
                class="button pagination__item pagination__item_control"
                :class="{
                    button_green: page == first,
                    button_orange: page != first,
                }"
                @click="$emit('set', first)"
            >
                {{ first }}
            </button>

            <div class="pagination__divider" v-show="isFirstDots">...</div>

            <div class="pagination__inner">
                <slot></slot>
            </div>

            <div class="pagination__divider" v-show="isLastDots">...</div>

            <button
                class="button pagination__item pagination__item_control"
                :class="{
                    button_green: page == pages,
                    button_orange: page != pages,
                }"
                @click="$emit('set', pages)"
            >
                {{ pages }}
            </button>

            <button @click="$emit('next')" class="button button_orange pagination__item pagination__item_action">
                <ArrowRight :size="22"></ArrowRight>
            </button>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ArrowLeft from 'vue-material-design-icons/ChevronLeft';
import ArrowRight from 'vue-material-design-icons/ChevronRight';

import last from 'lodash/last';
import first from 'lodash/first';

@Component({
    components: {
        ArrowLeft,
        ArrowRight,
    },
})
class PagePaginateControl extends Vue {
    @Prop(Array) views;
    @Prop(Number) page;
    @Prop(Number) pages;

    first = 1;

    get isFirstDots() {
        return first(this.views) !== this.first + 1 && this.pages > 5;
    }

    get isLastDots() {
        return last(this.views) !== this.pages - 1 && this.pages > 5;
    }
}

export default PagePaginateControl;
</script>
<style lang="scss">
.pagination {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: flex-end;

    &__wrapper {
        display: flex;
        position: relative;
        padding: 5px 15px;
    }

    &__inner {
        display: flex;
    }

    &__item {
        width: auto;
        min-width: 28px;
        height: 28px;
        border: none;
        margin: 0 3px !important;
        padding: 0 3px !important;
    }

    &__divider {
        display: inline-block;
        height: 28px;
        min-width: 28px;
        text-align: center;
    }
}
</style>
