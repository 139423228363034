<template>
    <VGridPageColumn sm-2 xs-12 width="15" border>
        <div class="nav-menu">
            <div class="nav-menu__header">
                <RouterLink
                    :to="{
                        name: 'request.create',
                    }"
                    class="button button_green button_fluid"
                    >Создать новое обращение</RouterLink
                >
            </div>

            <ul class="nav-menu__list">
                <template v-for="(item, index) in items">
                    <li :key="index" class="nav-menu__item" :class="item.class">
                        <RouterLink :to="item.to" class="nav-menu__item-link" nav-menu>
                            <component :is="item.icon" :size="24" fillColor="#606060"></component>

                            <span class="nav-menu__item-label">
                                {{ item.label }} <span v-show="item.counter">({{ item.counter }})</span>
                            </span>
                        </RouterLink>
                    </li>
                </template>
            </ul>
        </div>
    </VGridPageColumn>
</template>
<script>
// Components
import VButton from '../../components/VButton/VButton.vue';
import VGridPageColumn from '../../components/VGrid/VGridPageColumn';

import ArchiveArrowDownOutline from 'vue-material-design-icons/ArchiveArrowDownOutline';
import ArchiveArrowUpOutline from 'vue-material-design-icons/ArchiveArrowUpOutline';
import ArchiveOutline from 'vue-material-design-icons/ArchiveOutline';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';

const Workspace = namespace('workspace');

@Component({
    components: {
        VButton,
        VGridPageColumn,
    },
})
class NavMenu extends Vue {
    @Workspace.State menuInfo;
    @Workspace.State accessSecurityGroups;

    get items() {
        const items = [
            {
                to: {
                    name: 'request',
                    params: {
                        method: 'inbox',
                    },
                },
                icon: ArchiveArrowDownOutline,
                label: 'Входящие',
                counter: this.menuInfo?.inbox ?? 0,
            },
            {
                to: {
                    name: 'request',
                    params: {
                        method: 'outbox',
                    },
                },
                icon: ArchiveArrowUpOutline,
                label: 'Исходящие',
                counter: this.menuInfo?.outbox ?? 0,
            },
            {
                to: {
                    name: 'request',
                    params: {
                        method: 'archive',
                    },
                },
                icon: ArchiveOutline,
                label: 'Закрытые',
            },
        ];

        if (this.$_isExecutor || this.$_isAdmin) {
            items.push({
                to: {
                    name: 'request',
                    params: {
                        method: 'store',
                    },
                },
                icon: ArchiveOutline,
                label: 'Всe обращения',
                class: 'nav-menu__item_space-top',
            });
        }

        const groups = this.accessSecurityGroups ?? {};

        if (groups) {
            let groupKeys = [];

            if (this.menuInfo?.groups) {
                groupKeys = Object.keys(this.menuInfo.groups);
            }

            groups.forEach((group, index) => {
                let counter = 0;

                if (groupKeys.find((item) => item == group.id)) {
                    counter = this.menuInfo.groups[group.id];
                }

                const item = {
                    to: {
                        name: 'request',
                        params: {
                            method: group.id,
                        },
                    },
                    icon: ArchiveOutline,
                    label: group.name,
                    counter: counter,
                    class: index === 0 ? 'nav-menu__item_space-top' : '',
                };

                items.push(item);
            });
        }

        return items;
    }
}

export default NavMenu;
</script>
<style lang="scss">
.router-link-active[nav-menu] {
    font-weight: 500;
    background: #f1f6fe;
}

.nav-menu {
    margin: 0 10px;

    &__item {
        width: 100%;
        height: 40px;
        margin-bottom: 4px;
        font-size: 0.875rem;
        color: var(--text-black);

        &_space-top {
            margin-top: 30px;
        }
    }

    &__item-link {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 16px;
        border-radius: 8px;
        cursor: pointer;

        &:hover {
            background: #f1f6fe;
        }
    }

    &__item-label {
        margin-left: 8px;
    }
}
</style>