<template>
    <VModalWrapper :title="title" :is-loading="isLoading" v-on="listeners" v-bind="attributes">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form class="active-form" @submit.prevent="handleSubmit(submit)">
                <ValidationProvider class="active-form__field" name="title" rules="max:64" v-slot="{ errors }">
                    <label class="active-form__label active-form__label_required" for="service-title">Название</label>

                    <VInput
                        id="service-title"
                        name="title"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="service.title"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider
                    class="active-form__field"
                    name="subtitle"
                    rules="required|max:128"
                    v-slot="{ errors }"
                >
                    <label class="active-form__label active-form__label_required" for="service-subtitle"
                        >Описание</label
                    >

                    <VInput
                        id="service-title"
                        name="subtitle"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="service.subtitle"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider class="active-form__field" name="hours" rules="required" v-slot="{ errors }">
                    <label class="active-form__label active-form__label_required" for="service-hours"
                        >Время на решение обращения</label
                    >

                    <VInput
                        id="service-title"
                        name="hours"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="service.hours"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider class="active-form__field" name="categoryId" rules="required" v-slot="{ errors }">
                    <label class="active-form__label active-form__label_required" for="service-categoryId"
                        >Категория</label
                    >

                    <VSelect
                        id="security-group-categoryId"
                        name="categoryId"
                        label="title"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        :reduce="(x) => x.id"
                        :options="categories"
                        @submit="handleSubmit(submit)"
                        v-model="service.categoryId"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider class="active-form__field" name="observers" v-slot="{ errors }">
                    <label class="active-form__label" for="service-observers">Наблюдатели по умолчанию</label>

                    <VSelect
                        id="security-group-observers"
                        name="observers"
                        label="displayName"
                        class="active-form__control"
                        multiple
                        :class="{
                            error: errors[0],
                        }"
                        :options="users"
                        @submit="handleSubmit(submit)"
                        v-model="service.observers"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider
                    class="active-form__field"
                    name="securityGroupId"
                    rules="required"
                    v-slot="{ errors }"
                >
                    <label class="active-form__label active-form__label_required" for="service-securityGroupId"
                        >Группа ответственных</label
                    >

                    <VSelect
                        id="security-group-observers"
                        name="securityGroupId"
                        label="name"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        :options="securityGroups"
                        :reduce="(x) => x.id"
                        @submit="handleSubmit(submit)"
                        v-model="service.securityGroupId"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider
                    class="active-form__field"
                    name="executorId"
                    v-slot="{ errors }"
                    v-if="securityGroup"
                >
                    <label class="active-form__label" for="service-executorId">Исполнитель</label>

                    <VSelect
                        id="security-group-observers"
                        name="executorId"
                        label="displayName"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        :reduce="(x) => x.id"
                        :options="securityGroup.accounts"
                        :resetOnOptionsChange="true"
                        @submit="handleSubmit(submit)"
                        v-model="service.executorId"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <div class="active-form__actions">
                    <VButton type="button" class="active-form__action button_green-light" @click="hide">
                        Отмена
                    </VButton>

                    <VButton type="submit" class="active-form__action button_green"> Сохранить </VButton>
                </div>
            </form>
        </ValidationObserver>
    </VModalWrapper>
</template>
<script>
// Components
import VInput from '../components/VInput/VInput.vue';
import VButton from '../components/VButton/VButton.vue';
import VSelect from '../components/VSelect/VSelect.vue';
import VModalWrapper from '../components/VModalWrapper/VModalWrapper.vue';
import VContenteditable from '../components/VContenteditable/VContenteditable.vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalNames from '../../js/enums/ModalNames';
import ModalMixin from '../../js/vue/mixins/ModalMixin';
import { getAllUsers, getAllCategories, getAllSecurityGroups } from '../../js/admin-api';

@Component({
    components: {
        VInput,
        VButton,
        VSelect,
        VModalWrapper,
        VContenteditable,

        ValidationObserver,
        ValidationProvider,
    },
})
class VAdminServiceCard extends ModalMixin {
    @Prop({ type: String, default: ModalNames.ADMIN_PANEL_SERVICE_CARD })
    name;
    @Prop({ type: String, default: 'Карточка сервиса' })
    defaultTitle;

    service = null;

    users = [];
    categories = [];
    securityGroups = [];

    isLoading = true;

    submit() {
        this.resolve(this.service);

        this.hide();
    }

    async fetchUsers() {
        if (!this.users?.length) {
            const response = await getAllUsers(1, '', 100000);
            this.users = response.data.data.map((user) => ({
                id: user.id,
                displayName: user.enabled ? user.displayName : user.displayName + ' (откл.)',
            }));
        }
    }

    async fetchCategories() {
        if (!this.categories?.length) {
            const response = await getAllCategories(1, '', 100000);
            this.categories = response.data.data.map((category) => ({
                id: category.id,
                title: category.title,
            }));
        }
    }

    async fetchSecurityGroups() {
        if (!this.securityGroups?.length) {
            const response = await getAllSecurityGroups(1, '', 100000);
            this.securityGroups = response.data.data.map((securityGroup) => ({
                id: securityGroup.id,
                name: securityGroup.name,
                accounts: securityGroup.accounts,
            }));
        }
    }

    get securityGroup() {
        if (!this.securityGroups || !this.service.securityGroupId) {
            return null;
        }

        return this.securityGroups.find((securityGroup) => securityGroup.id === this.service.securityGroupId);
    }

    async beforeOpen(event) {
        this.service = event?.params?.service;

        this.isLoading = true;

        try {
            await Promise.all([this.fetchUsers(), this.fetchCategories(), this.fetchSecurityGroups()]);
        } finally {
            this.isLoading = false;
        }
    }

    beforeClose() {
        this.service = null;
    }
}

export default VAdminServiceCard;
</script>
