import { DateTime } from 'luxon';
import RequestStatusType from './RequestStatusType';

export default class RequestStateType {
    static GOOD = 0;
    static NORMAL = 1;
    static WARN = 2;
    static FATAL = 3;

    static type(reqeust) {
        if (this.isNormal(reqeust)) return RequestStateType.NORMAL;

        if (this.isFatal(reqeust)) return RequestStateType.FATAL;

        if (this.isWarn(reqeust)) return RequestStateType.WARN;

        return RequestStateType.GOOD;
    }

    static isGood(request) {
        return !this.isWarn(request);
    }

    static isNormal(request) {
        return [RequestStatusType.WORKUP].indexOf(request.status) === -1;
    }

    static isWarn(request) {
        return (
            request.status === RequestStatusType.WORKUP && this.UTC().plus({ days: 1 }) > this.dateUTC(request.deadline)
        );
    }

    static isFatal(request) {
        return request.status === RequestStatusType.WORKUP && this.UTC() > this.dateUTC(request.deadline);
    }

    static UTC() {
        return DateTime.local().setZone('utc');
    }

    static dateUTC(date) {
        return DateTime.fromSQL(date, { zone: 'utc' });
    }
}
