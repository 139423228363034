<template>
    <span :style="styleOptions" class="v-spinner color_green"></span>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
class VSpinner extends Vue {
    @Prop({ type: String, default: '#00A55A' }) color;
    @Prop({ type: String, default: '2rem' }) width;
    @Prop({ type: String, default: '2rem' }) height;
    @Prop({ type: String, default: '0.2em' }) borderSize;

    get styleOptions() {
        return {
            'width': this.width,
            'height': this.height,
            'color': this.color,
            '--border-size': this.borderSize,
        };
    }
}

export default VSpinner;
</script>
<style lang="scss">
.v-spinner {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: var(--border-size) solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner 0.75s linear infinite;
    animation: spinner 0.75s linear infinite;
}

.color_green {
    color: $green;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
