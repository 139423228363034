<template>
    <VGridPageColumn class="grid-page-column__context_no-bottom-padding" sm-4 xs-12>
        <ModuleColumnBlock class="request__tools">
            <BaseTools
                @change-filter="onChangeFilter"
                @search="onSearch"
                @sort="onSort"
                @toggleSettings="toggleSettingsMenu"
            ></BaseTools>
        </ModuleColumnBlock>

        <template v-if="displayRequestTypeIsTable">
            <VueCustomScrollbar :class="vScrollClass">
                <RequestTableHeader></RequestTableHeader>

                <RequestStorePagination :endpoint="endpoint" :per-page="15" @loaded="onLoadedRequests" v-model="page">
                    <template v-slot="{ request }">
                        <RouterLink :to="getRequestLink(request)" class="router-link_disarm">
                            <RequestTableRow :request="request"></RequestTableRow>
                        </RouterLink>
                    </template>
                </RequestStorePagination>
            </VueCustomScrollbar>
        </template>

        <template v-else>
            <VueCustomScrollbar :class="vScrollToolsClass">
                <RequestStorePagination :endpoint="endpoint" :per-page="5" @loaded="onLoadedRequests" v-model="page">
                    <template v-slot="{ request }">
                        <VGridRow>
                            <RequestTableCard :request="request"></RequestTableCard>
                        </VGridRow>
                    </template>
                </RequestStorePagination>
            </VueCustomScrollbar>
        </template>

        <ColumnLayer v-model="showSettingsMenu">
            <ColumnLayerInner>
                <VForm @submit="saveSettings">
                    <VFormField label="Тип отображения:">
                        <VueSelect
                            :options="options"
                            :reduce="(x) => x.value"
                            class="v-select-light"
                            placeholder="Тип отображения"
                            v-model="displayType"
                        ></VueSelect>
                    </VFormField>

                    <div class="active-form__actions">
                        <VButton @click.prevent="toggleSettingsMenu" class="button_orange active-form__action"
                            >Закрыть
                        </VButton>
                        <VButton class="button_green active-form__action" type="submit">Сохранить</VButton>
                    </div>
                </VForm>
            </ColumnLayerInner>
        </ColumnLayer>
    </VGridPageColumn>
</template>
<script>
import Vue from 'vue';
import http from '../../../js/http';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import RequestStoreDisplayType from '@/js/enums/RequestStoreDisplayType';
import RequestStoreDisplayTitle from '@/js/enums/RequestStoreDisplayTitle';

import ModuleColumn from '@/views/modules/default/ModuleColumn';
import ModuleColumnBlock from '@/views/modules/default/ModuleColumnBlock';
import ModuleColumnScroll from '@/views/modules/default/ModuleColumnScroll';
import ModuleColumnScrollInner from '@/views/modules/default/ModuleColumnScrollInner';
import ModuleColumnScrollWrapper from '@/views/modules/default/ModuleColumnScrollWrapper';

import VueCustomScrollbar from 'vue-custom-scrollbar';
import VForm, { VFormField } from '@/views/components/VForm';
import BaseTools from '@/views/components/tools/BaseTools';
import VButton from '@/views/components/VButton/VButton.vue';
import ColumnLayer from '@/views/components/modules/ColumnLayer';
import ColumnLayerInner from '@/views/components/modules/ColumnLayerInner';
import RequestTableRow from '@/views/components/RequestTableRow';
import RequestTableCard from '@/views/components/RequestTableCard';
import RequestTableHeader from '@/views/components/RequestTableRow/RequestTableHeader';
import RequestStoreLoader from '@/views/components/RequestStoreLoader';
import RequestStorePagination from '@/views/components/RequestStorePagination';

import { VGridPageColumn, VGridRow } from '../../components';

const options = Object.keys(RequestStoreDisplayTitle).map((key) => ({
    value: parseInt(key),
    label: RequestStoreDisplayTitle[key],
}));
const Account = namespace('account');
const Workspace = namespace('workspace');

@Component({
    components: {
        ModuleColumn,
        ModuleColumnBlock,
        ModuleColumnScroll,
        ModuleColumnScrollInner,
        ModuleColumnScrollWrapper,
        VueCustomScrollbar,

        VForm,
        VFormField,
        BaseTools,
        VButton,
        ColumnLayer,
        ColumnLayerInner,
        RequestStoreLoader,
        RequestStorePagination,
        RequestTableRow,
        RequestTableCard,
        RequestTableHeader,

        VGridRow,
        VGridPageColumn,
    },
})
class RequestStore extends Vue {
    @Account.State account;
    @Account.Mutation REQUEST_STORE_SETTINGS_UPDATE;

    @Workspace.Mutation SET_REQUESTS_CONTEXT;

    page = 1;
    filter = {};
    options = options;
    showSettingsMenu = false;
    displayType = RequestStoreDisplayType.CARD;

    saveSettings() {
        const id = this.requestStoreSettings.id;
        const data = {
            accountId: this.account.id,
            displayType: this.displayType ? this.displayType : RequestStoreDisplayType.CARD,
        };
        const method = id ? http.put : http.post;
        const endpoint = id ? `/request-store-settings/${id}` : '/request-store-settings';

        method(endpoint, data).then(({ data }) => {
            this.toggleSettingsMenu();
            this.REQUEST_STORE_SETTINGS_UPDATE(data);
        });
    }

    toggleSettingsMenu() {
        if (this.showSettingsMenu) {
            this.displayType = this.requestStoreSettings.displayType;
        }

        this.showSettingsMenu = !this.showSettingsMenu;
    }

    onSort(sort) {
        this.filter.sort = sort;

        this.$bus.emit('RequestStoreFilter:Update', this.filter);
    }

    onSearch(search) {
        this.filter.search = search;

        this.$bus.emit('RequestStoreFilter:Update', this.filter);
    }

    onChangeFilter(filter) {
        this.filter.filter = filter;

        this.$bus.emit('RequestStoreFilter:Update', this.filter);
    }

    onLoadedRequests({ requests }) {
        this.SET_REQUESTS_CONTEXT(requests);
    }

    getRequestLink(request) {
        return {
            name: 'request',
            params: {
                id: request.id,
                method: this.$route.params.method,
            },
        };
    }

    get endpoint() {
        const id = this.$route.params.id;
        const method = this.$route.params.method;

        if (method === 'view') return `/requests/${method}/${id}`;

        if (!isNaN(method)) return `/requests/group/${method}`;

        return `/requests/${method}`;
    }

    get displayRequestTypeIsTable() {
        return this.requestStoreSettings.displayType === RequestStoreDisplayType.TABLE;
    }

    get vScrollClass() {
        return {
            'v-scroll_hidden': true,
            'request__scroll': true,
        };
    }

    get vScrollToolsClass() {
        return {
            'v-scroll_hidden': true,
        };
    }

    get requestStoreSettings() {
        return this.account && this.account.requestStoreSettings
            ? this.account.requestStoreSettings
            : {
                  displayType: RequestStoreDisplayType.CARD,
              };
    }

    @Watch('$route.params.method')
    f() {
        this.filter = {
            sort: { type: 0, reverse: true },
        };
        this.$bus.emit('tools:clear');
        this.$bus.emit('RequestStoreFilter:UpdateForce', this.filter);
    }

    created() {
        this.displayType = this.requestStoreSettings.displayType;
    }
}

export default RequestStore;
</script>
<style lang="scss">
.request {
    &__actions {
        width: 100%;
        display: flex;
        position: absolute;
        padding: 0 10px;
        left: 0;
        bottom: 0;
        justify-content: flex-end;
    }

    &__tools {
        max-height: 100%;
    }

    &__scroll {
        width: auto;
        overflow-x: hidden;

        &_without-overflow {
            overflow-y: visible !important;
        }
    }

    &__table-header {
        margin: 0 -10px;
    }
}
</style>
