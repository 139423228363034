import { DateTime } from 'luxon';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
class DateLainer extends Vue {
    static SERVER_TIMEZONE = 'UTC';
    static CLIENT_TIMEZONE = 'local';
    static LOCALE_FORMAT = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };

    static HISTORY_FORMAT = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };

    $_toLocalDate($date) {
        return DateTime.fromSQL($date, { zone: DateLainer.SERVER_TIMEZONE })
            .setZone(DateLainer.CLIENT_TIMEZONE)
            .toLocaleString(DateLainer.LOCALE_FORMAT);
    }

    $_toLocalDateHistory($date) {
        return DateTime.fromSQL($date, { zone: DateLainer.SERVER_TIMEZONE })
            .setZone(DateLainer.CLIENT_TIMEZONE)
            .toLocaleString(DateLainer.HISTORY_FORMAT);
    }

    $_toLocalRelativeDate($date) {
        return DateTime.fromSQL($date, { zone: DateLainer.SERVER_TIMEZONE })
            .setZone(DateLainer.CLIENT_TIMEZONE)
            .toRelative({
                padding: -1000,
            });
    }
}

Vue.mixin(DateLainer);

export { DateLainer };
