<template>
    <a
        class="comment-attachment"
        :class="{
            'comment-attachment_dark': dark,
            'comment-attachment_error': attachment.errorMessage,
        }"
        target="_blank"
        :href="attachmentUri"
        :download="attachment.id + '-' + attachment.name"
    >
        <template v-if="isBrowsableAttachment">
            <img
                class="comment-attachment__image"
                :src="attachmentUri"
                :alt="attachment.name"
                @click.stop.prevent="showImagePopup()"
                v-tooltip="{ content: 'Открыть на весь экран', classes: tooltipClasses }"
            />
        </template>

        <template v-else>
            <span class="comment-attachment__extension">
                {{ extension }}
            </span>
        </template>

        <span
            class="comment-attachment__label"
            :class="{
                'comment-attachment__label_error': attachment.errorMessage,
            }"
            v-tooltip="{ content: attachment.name, classes: tooltipClasses }"
        >
            {{ attachment.name }}

            <span class="comment-attachment__error-message" v-if="attachment.errorMessage">
                <br />
                {{ attachment.errorMessage }}
            </span>
        </span>

        <VProgressRadial
            class="comment-attachment__progress-bar"
            :progress="attachment.progress"
            v-if="attachment.isUploading"
        ></VProgressRadial>

        <button
            class="comment-attachment__delete-button"
            v-else-if="allowDeletion || attachment.errorMessage"
            v-tooltip="'Удалить файл'"
            @click.stop.prevent="$emit('delete', attachment)"
        >
            <TimesSvg></TimesSvg>
        </button>
    </a>
</template>
<script>
// Components
import VProgressRadial from '../../../components/VProgress/VProgressRadial.vue';

import TimesSvg from '@/images/times.svg';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

let serverURL = process.env.VUE_APP_SERVER_URL;

if (!serverURL) {
    serverURL = '';
}

if (serverURL.endsWith('/')) {
    serverURL = serverURL.slice(0, -1);
}

const downloadBaseUrl = serverURL + '/files';

@Component({
    components: {
        VProgressRadial,

        TimesSvg,
    },
})
class ChatComment extends Vue {
    @Prop({ type: Boolean, default: false })
    dark;

    @Prop({ type: Boolean, default: false })
    allowDeletion;

    @Prop({ type: Object, required: true })
    attachment;

    showImagePopup() {
        this.$bus.emit('ImagePopup:Show', this.attachmentUri);
    }

    get extension() {
        if (this.attachment?.name) {
            const fragments = this.attachment.name.split('.');
            const extension = fragments[fragments.length - 1];

            return extension;
        }

        return undefined;
    }

    get attachmentUri() {
        if (this.attachment.dataURL) {
            return this.attachment.dataURL;
        }

        return this.attachment.publicId ? `${downloadBaseUrl}/${this.attachment.publicId}` : '';
    }

    get tooltipClasses() {
        return this.dark ? 'tooltip-light' : '';
    }

    get isBrowsableAttachment() {
        return this.attachment.type.match(/^image\/*/);
    }

    @Watch('attachment.error')
    fd() {
        console.log(this.attachment);
    }
}

export default ChatComment;
</script>
<style lang="scss">
:root {
    --comment-attachment-color: #00a75c;
    --comment-attachment-color-dark: #ffffff;

    --comment-attachment-background: #f2f5f9;
    --comment-attachment-background-dark: #383838;

    --comment-attachment-extension-background: var(--color-green);
    --comment-attachment-extension-background-error: #dedddd;
}

.comment-attachment {
    position: relative;
    display: flex;
    height: 64px;
    max-width: 330px;
    color: var(--comment-attachment-color);
    background: var(--comment-attachment-background);
    cursor: pointer;
    border-radius: 10px;

    &_dark {
        color: var(--comment-attachment-color-dark);
        background: var(--comment-attachment-background-dark);
    }

    &_error {
        color: var(--error-color);

        .comment-attachment__label {
            margin: 16px 48px 16px 10px;
        }

        .comment-attachment__extension {
            background: var(--comment-attachment-extension-background-error);
        }
    }

    &__image,
    &__extension {
        flex: 0 0 56px;
        width: 56px;
        height: 56px;
        min-height: 56px;
        max-height: 56px;
        border-radius: 10px;
        margin: 4px;
    }

    &__extension {
        display: inline-block;
        font-size: 1.125rem;
        color: var(--color-white);
        line-height: 3.625rem;
        text-align: center;
        text-transform: uppercase;
        user-select: none;
        overflow: hidden;
        white-space: nowrap;
        background: var(--comment-attachment-extension-background);
    }

    &__image {
        width: 64px;
        object-fit: cover;
        overflow: hidden;
    }

    &__label {
        display: inline-block;
        margin: 24px 48px 24px 10px;
        max-width: 100%;
        line-height: 1rem;
        font-size: 0.875rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__error-message {
        line-height: 0.875rem;
        font-size: 0.75rem;
    }

    &__progress-bar,
    &__delete-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 28px;
        height: 28px;
        margin: 0;
        padding: 8px;
    }

    &__delete-button {
        display: flex;

        & svg {
            fill: var(--error-color);
            width: 12px;
            height: 12px;
        }
    }

    &__progress-bar {
        padding: 6px;
    }
}
</style>