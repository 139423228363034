import http from './http';

////////////////////////////////////////////////////////////////

export function findUser(id) {
    return http.get(`/admin/users/${id}`);
}

export function getAllUsers(page, search = '', limit = 15) {
    const params = { page, limit };

    if (search) {
        params.search = search;
    }

    return http.get(`/admin/users`, { params });
}

export function createUser(user) {
    return http.post(`/admin/users`, user);
}

export function updateUser(user) {
    return http.patch(`/admin/users/${user.id}`, user);
}

////////////////////////////////////////////////////////////////

export function getAllCities(page, search = '', limit = 15) {
    const params = { page, limit };

    if (search) {
        params.search = search;
    }

    return http.get(`/admin/cities`, { params });
}

export function getAllTimezones() {
    return http.get(`/admin/timezones`);
}

export function createCity(city) {
    return http.post(`/admin/cities`, city);
}

export function updateCity(city) {
    return http.patch(`/admin/cities/${city.id}`, city);
}

////////////////////////////////////////////////////////////////

export function getAllSecurityGroups(page, search = '', limit = 15) {
    const params = { page, limit };

    if (search) {
        params.search = search;
    }

    return http.get(`/admin/security-groups`, { params });
}

export function createSecurityGroup(securityGroup) {
    return http.post(`/admin/security-groups`, securityGroup);
}

export function updateSecurityGroup(securityGroup) {
    return http.patch(`/admin/security-groups/${securityGroup.id}`, securityGroup);
}

////////////////////////////////////////////////////////////////

export function getAllCategories(page, search = '', limit = 15) {
    const params = { page, limit };

    if (search) {
        params.search = search;
    }

    return http.get(`/admin/categories`, { params });
}

export function createCategory(category) {
    return http.post(`/admin/categories`, category);
}

export function updateCategory(category) {
    return http.patch(`/admin/categories/${category.id}`, category);
}

export function deleteCategory(category) {
    return http.delete(`/admin/categories/${category.id}`);
}

////////////////////////////////////////////////////////////////

export function getAllServices(page, search = '', limit = 15) {
    const params = { page, limit };

    if (search) {
        params.search = search;
    }

    return http.get(`/admin/services`, { params });
}

export function createService(service) {
    return http.post(`/admin/services`, service);
}

export function updateService(service) {
    return http.patch(`/admin/services/${service.id}`, service);
}

export function deleteService(service) {
    return http.delete(`/admin/services/${service.id}`);
}
