<template>
    <VGridPageColumn sm-4 xs-12>
        <VGridRow class="grid-row_padding-bottom">
            <VGridCol md-2 xs-3>
                <RouterLink
                    to="/request/categories"
                    class="button button_no-shadow button_center button_without-padding button_padding-x request-create-store__button-back"
                >
                    <ChevronLeftIcon class="button__title" :size="24" fill-color="#606060"> </ChevronLeftIcon>

                    Назад
                </RouterLink>
            </VGridCol>

            <VGridCol md-10 xs-9>
                <BaseToolsSearch v-model="searchString" placeholder="Поиск" />
            </VGridCol>
        </VGridRow>
        <VGridScroll class="v-scroll_hidden">
            <template v-if="!isLoadingData">
                <VGridRow class="grid-row_padding-y">
                    <span class="text-lg">Сервис:</span>
                </VGridRow>

                <VGridRow class="grid-row_without-padding grid-row_padding-y">
                    <VGridCol role="list">
                        <transition-group name="animation-fadedown">
                            <template v-for="service in filteredServices">
                                <RouterLink :key="service.id" :to="getServiceUrl(service)" class="grid-col">
                                    <ResourceItem
                                        :item="service"
                                        :default-subtitle="`Категория: ${service.category.title}`"
                                    ></ResourceItem>
                                </RouterLink>
                            </template>
                        </transition-group>
                    </VGridCol>
                </VGridRow>
            </template>

            <VGridRow class="grid-row_fluid grid-row_center" v-else>
                <VSpinner></VSpinner>
            </VGridRow>
        </VGridScroll>
    </VGridPageColumn>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { ResourceItem, ServiceForm, ResourceLayer } from './components';
import { VGridRow, VGridCol, VGridScroll, VActiveForm, VGridPageColumn } from '../../components';

import VButton from '@/views/components/VButton/VButton.vue';
import VSpinner from '@/views/components/VProgress/VSpinner.vue';
import BaseToolsSearch from '@/views/components/tools/BaseToolsSearch';

import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft';
import http from '../../../js/http';

@Component({
    components: {
        VSpinner,
        VGridRow,
        VGridCol,
        VGridScroll,
        VActiveForm,
        VGridPageColumn,

        ChevronLeftIcon,

        ResourceItem,
        ServiceForm,
        ResourceLayer,

        VButton,
        BaseToolsSearch,
    },
})
class RequestCreateStore extends Vue {
    service = {};
    services = [];
    searchString = '';
    isLoadingData = false;

    getServiceUrl(item) {
        const serviceId = item.id;

        return `/request/categories/${this.categoryId}/services/${serviceId}`;
    }

    get categoryId() {
        return this.$route.params.categoryId;
    }

    get filteredServices() {
        if (this.searchString === '') return this.services;

        const searchString = this.searchString.toLowerCase();

        return this.services.filter((service) => service.title.toLowerCase().indexOf(searchString) !== -1);
    }

    created() {
        this.isLoadingData = true;

        http.get(`/categories/${this.categoryId}/services?with=observers,category`).then(({ data }) => {
            this.services = data;
            this.isLoadingData = false;
        });
    }
}

export default RequestCreateStore;
</script>
