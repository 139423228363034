const customEager = (ctx) => {
    const ctxExtend = ctx;
    if (ctxExtend.errors.length && ctxExtend.initialValue !== ctxExtend.value) {
        // clear list of errors when last value not equal to new value
        ctxExtend.errors = [];
    }
    ctxExtend.initialValue = ctxExtend.value;

    // validate immediately after leaving the field.
    return { on: ['blur'], debounce: 0 };
};

export { customEager };
