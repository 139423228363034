import { serverErrors, serverMessages, clientMessages, defaultMessages } from './enums/ErrorMessages';

export function errorMessage(error, defaultMessage = '') {
    let key = null;
    let message = null;

    key = error?.response?.data?.title;
    // If an error from an api tries to get a message for this error.
    if (key) {
        const errorMessage = serverErrors[key];

        if (errorMessage) {
            return errorMessage;
        }
    }

    message = error?.response?.data?.message;
    if (message) {
        const errorMessage = serverMessages[message];

        if (errorMessage) {
            return errorMessage;
        }
    }

    // If an error from the js tries to get a message for this error.
    message = error?.message;
    if (message) {
        const errorMessage = clientMessages[message];

        if (errorMessage) {
            return errorMessage;
        }
    }

    return defaultMessage ? defaultMessage : defaultMessages.SOMETHING_WENT_WRONG;
}
