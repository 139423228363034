<template>
    <VueCustomScrollbar class="request-observers">
        <div class="request-observers__control">
            <VSelect
                label="displayName"
                placeholder="Выберите наблюдателя"
                :options="accounts"
                @input="addObserver"
                v-model="observer"
            ></VSelect>
        </div>

        <ul class="request-observers__list">
            <template v-for="observer in observers">
                <li class="request-observers__item" :key="observer.id">
                    <UserCard :account="observer"></UserCard>

                    <button class="request-observers__remove" @click="deleteObserver(observer)">
                        <TrashSvg></TrashSvg>
                    </button>
                </li>
            </template>
        </ul>
    </VueCustomScrollbar>
</template>
<script>
// Components
import VSelect from '@/views/components/VSelect/VSelect.vue';
import UserCard from '@/views/components/VUser/UserCard.vue';
import VueCustomScrollbar from 'vue-custom-scrollbar';

import TrashSvg from '@/images/trash.svg';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const Workspace = namespace('workspace');

@Component({
    components: {
        VSelect,
        UserCard,

        VueCustomScrollbar,

        TrashSvg,
    },
})
class RequestViewTabObservers extends Vue {
    @Prop({ type: Object, required: true })
    request;

    @Prop({ type: Array, required: true })
    observers;

    @Workspace.State((state) => state.accounts.filter((account) => account.enabled))
    accounts;

    observer = null;

    addObserver(observer) {
        const index = this.observers.findIndex((item) => item.id === observer.id);

        if (index === -1) {
            this.$emit('addObserver', observer);
        }

        this.observer = null;
    }

    deleteObserver(observer) {
        this.$emit('deleteObserver', observer);
    }
}

export default RequestViewTabObservers;
</script>
<style lang="scss">
:root {
    --request-observers-remove-color: var(--error-color);
    --request-observers-observer-border: #e4eaef;
}

.request-observers {
    &__list,
    &__control {
        margin: 24px;
    }

    &__item {
        display: flex;
        margin: 16px 0 0 0;
        padding: 16px;
        border: 1px solid var(--request-observers-observer-border);
        border-radius: 12px;
    }

    &__remove {
        width: 32px;
        height: 32px;
        padding: 6px;
        fill: var(--request-observers-remove-color);
    }
}
</style>
