<template>
    <VueSelect :options="options" class="v-select-light" multiple placeholder="Сервис" v-model="value"></VueSelect>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';

const Workspace = namespace('workspace');

@Component
class FilterService extends Vue {
    @Workspace.State((state) => state.services) storeServices;
    @Workspace.State((state) => state.categories) storeCategories;

    value = [];
    categories = [];

    getValue() {
        return this.value.map((value) => value.value);
    }

    onChange() {
        this.$bus.emit('filter-service:changed', this.value);
    }

    reset() {
        this.value = [];
    }

    onGroupChange(groups) {
        this.categories = this.storeCategories
            .filter((category) => groups.some((group) => group.value === category.groupId))
            .map((category) => ({
                value: category.id,
                label: category.title,
                groupId: category.groupId,
            }));
    }

    onCategoryChange(categories) {
        this.categories = categories;

        this.value = this.value.filter((service) =>
            this.categories.some((category) => category.value === service.categoryId),
        );
    }

    get options() {
        let options = this.storeServices;

        if (this.categories.length) {
            options = options.filter((service) =>
                this.categories.some((category) => category.value === service.categoryId),
            );
        }

        return options.map((service) => ({
            value: service.id,
            label: service.title,
            categoryId: service.categoryId,
        }));
    }

    created() {
        this.$bus.on('filter-category:changed', this.onCategoryChange);
        this.$bus.on('filter-group:changed', this.onGroupChange);
    }

    beforeDestroy() {
        this.$bus.off('filter-category:changed', this.onCategoryChange);
        this.$bus.off('filter-group:changed', this.onGroupChange);
    }
}

export default FilterService;
</script>
