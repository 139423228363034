export default class RequestHistoryActionType {
    static CLOSED = 0;
    static DENIED = 1;
    static PAUSED = 2;
    static REWORK = 3;
    static WORKUP = 4;
    static FINISH = 5;
    static CHANGE_EXECUTOR = 6;
    static CHANGE_SECURITY_GROUP = 7;
    static CREATED = 8;
    static COMMENTED = 9;
    static SYSTEM_CLOSED = 10;
    static SERVICE_CHANGED = 11;
}
