<template>
    <input :placeholder="placeholder" :type="type" :value="value" @input="input" class="input_light" />
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: 'text',
        },
        value: {
            type: [String, Number, Boolean],
            default: 'text',
        },
        placeholder: {
            type: String,
            default: 'text',
        },
    },
    data: () => ({}),
    methods: {
        input(event) {
            this.$emit('input', event.target.value);
        },
    },
};
</script>
<style lang="scss">
.input {
    &_light {
        width: 100%;
        outline: none;
        line-height: 2;
        padding: 4px 8px;
        font: 400 0.85rem Montserrat;
        background-color: var(--color-white);
        border: none;
        border-bottom: 1px solid #b1b1b1;
        box-sizing: border-box;
    }
}
</style>
