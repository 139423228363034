<template>
    <Modal class="modal" style="z-index: 9999" :name="name" v-on="$listeners" v-bind="$attrs">
        <div class="modal__wrapper">
            <span class="modal__button modal__button_close" @click="hide">
                <TimesSvg></TimesSvg>
            </span>

            <div class="modal__body">
                <slot name="header">
                    <h3 class="modal__row modal__row_title">{{ title }}</h3>
                </slot>

                <template v-if="isLoading">
                    <div class="modal__loader">
                        <VSpinner></VSpinner>
                    </div>
                </template>

                <template v-else>
                    <div class="modal__row modal__row_content">
                        <slot></slot>
                    </div>
                </template>
            </div>
        </div>
    </Modal>
</template>
<script>
// Components
import TimesSvg from '@/images/times.svg';
import VSpinner from '../VProgress/VSpinner.vue';
import { VGridCol, VGridRow, VGridFlex } from '..';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    components: {
        VSpinner,
        VGridCol,
        VGridRow,
        VGridFlex,

        TimesSvg,
    },
})
class VModalWrapper extends Vue {
    @Prop({ type: String, required: true })
    name;
    @Prop({ type: String, default: '' })
    title;
    @Prop({ type: Boolean, default: false })
    isLoading;

    hide() {
        this.$modal.hide(this.name);
    }
}
export default VModalWrapper;
</script>
<style lang="scss">
:root {
    --modal-header-title: #484848;
    --modal-background: var(--color-white);
    --modal-section-separator: #e5e4e5;
    --modal-overlay-background: rgba(0, 0, 0, 0.46);
    --modal-close-button-fill: #484848;
}

.modal {
    .v--modal-background-click .v--modal {
        background: none;
        box-shadow: none;
    }

    &.v--modal-overlay .v--modal-box {
        overflow: visible;
    }

    &.v--modal-overlay {
        background-color: var(--modal-overlay-background);
    }

    &__body {
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 60px;
        border-radius: 12px;
        background-color: var(--modal-background);
    }

    &__loader {
        display: flex;
        width: 100%;
        min-height: 100px;
        align-items: center;
        justify-content: center;
    }

    &__wrapper {
        padding: 60px 0;
    }

    &__row {
        display: flex;
        width: 100%;
        flex: 0 0 100%;

        &_title {
            display: inline-block;
            width: 100%;
            padding: 0;
            margin: 0 0 30px 0;
            font-size: 1.7rem;
            font-weight: 500;
            overflow: hidden;
            text-align: center;
            text-overflow: ellipsis;
            color: var(--modal-header-title);
        }

        &_content {
            flex-direction: column;
        }
    }

    &__button {
        &_close {
            position: absolute;
            fill: var(--modal-close-button-fill);
            top: 60px;
            right: 0;
            margin-left: auto;
            width: 16px;
            height: 16px;
            padding: 22px;
            box-sizing: content-box;
            cursor: pointer;
        }
    }
}
</style>
