import Vue from 'vue';

export const breakpoints = [
    { type: 'mobile', width: 576 },
    { type: 'tablet', width: 1200 },
    { type: 'desktop', width: 8192 },
];

const isType = (type) => {
    return type === deviceType();
};

const deviceType = () => {
    const width = window.innerWidth;
    const device = breakpoints.find((breakpoint) => {
        return width < breakpoint.width;
    });

    return device ? device.type : 'unknown';
};

Vue.use({
    install(Vue) {
        Vue.prototype.$isType = isType;

        Vue.prototype.$isMobile = () => isType('mobile');

        Vue.prototype.$isTablet = () => isType('tablet');

        Vue.prototype.$isDesktop = () => isType('desktop');
    },
});
