<template>
    <div :class="borderStyle" :style="widthStyles" class="module__col">
        <slot></slot>
    </div>
</template>
<script>
export default {
    props: {
        width: {
            type: String,
            default: '100',
        },
        border: Boolean,
    },
    computed: {
        borderStyle() {
            return {
                module__col_border: this.border,
            };
        },
        widthStyles() {
            return {
                width: this.width + '%',
            };
        },
    },
};
</script>
<style lang="scss">
.module {
    &__col {
        display: flex;
        position: relative;
        padding: 0 10px;
        flex-direction: column;

        &_border {
            border-right: 3px solid rgba(44, 44, 44, 0.12);
        }

        &_without-padding {
            padding: 0;
        }
    }
}
</style>
