<template>
    <VGridLayer :value="value" z-index="1001">
        <VActiveForm :rules="rules" @submit="submit" class="grid-flex_padding-y" name="request.resource.form">
            <VGridScroll>
                <slot></slot>
            </VGridScroll>
            <VGridRow class="grid-row_justify-end">
                <VButton @click="toggleValue" class="button_orange button_space-right">Отмена</VButton>

                <VButton class="button_green" type="submit">Сохранить</VButton>
            </VGridRow>
        </VActiveForm>
    </VGridLayer>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';

import VButton from '@/views/components/VButton/VButton.vue';

import { VGridRow, VGridLayer, VGridScroll, VActiveForm } from '../../../components';

@Component({
    components: {
        VGridRow,
        VGridLayer,
        VActiveForm,
        VGridScroll,
        VButton,
    },
})
class ResourceLayer extends Vue {
    @Prop({ type: Boolean, default: false }) value;
    @Prop({ type: Object, default: () => ({}) }) rules;
    @Prop({ type: Function, default: () => {} }) submit;

    @Emit('input')
    toggleValue() {
        return !this.value;
    }
}

export default ResourceLayer;
</script>
