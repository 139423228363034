<template>
    <VGridFlex class="grid-flex_column">
        <VActiveFormItem form="request.resource.form" name="id" :value="model.id" v-show="false"></VActiveFormItem>
        <VActiveFormItem form="request.resource.form" name="title" label="Имя:" :value="model.title">
            <InputLight placeholder="Имя" name="title" v-model="model.title"></InputLight>
        </VActiveFormItem>
        <VActiveFormItem form="request.resource.form" name="subtitle" label="Описание:" :value="model.subtitle">
            <InputLight name="subtitle" placeholder="Описание" v-model="model.subtitle"></InputLight>
        </VActiveFormItem>
    </VGridFlex>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import clone from 'lodash/clone';

import { InputLight, VGridFlex, VActiveFormItem } from '../../../components';

@Component({
    components: {
        InputLight,
        VGridFlex,
        VActiveFormItem,
    },
})
class CategoryForm extends Vue {
    @Prop({
        type: Object,
        default: () => ({
            title: '',
            subtitle: '',
        }),
    })
    category;
    instance = {};

    get model() {
        return this.category.id ? (this.instance = clone(this.category)) : this.category;
    }
}

export default CategoryForm;
</script>
