import Vue from 'vue';
import Vuex from 'vuex';
import http from './js/http';
import store from './store/index';

Vue.use(Vuex);

const modules = store;

const vueStore = new Vuex.Store({
    modules,
    strict: process.env.NODE_ENV !== 'production',
});

let isVuexBooted = false;

export { isVuexBooted };

export const bootVuex = () => {
    if (isVuexBooted) {
        return;
    }

    return http.get('source-data').then((response) => {
        const app = response.data.app;
        app.securityGroups.forEach((securityGroup) => {
            securityGroup.accounts.sort((left, right) => {
                return left.displayName.localeCompare(right.displayName);
            });
        });
        const promises = [];

        Object.keys(modules).forEach((module) => {
            if (modules[module].actions && modules[module].actions.BOOT) {
                promises.push(vueStore.dispatch(`${module}/BOOT`, app));
            }
        });

        Promise.all(promises).then(() => {
            isVuexBooted = true;
            Vue.bus.emit('Vuex::Booted');
        });
    });
};

export default vueStore;
