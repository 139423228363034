<template>
    <FlatPickr :config="config" class="input input_light" placeholder="Период" required v-model="value"></FlatPickr>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Russian } from 'flatpickr/dist/l10n/ru.js';

@Component
class FilterPeriod extends Vue {
    value = '';
    config = {
        mode: 'range',
        weekNumbers: true,
        dateFormat: 'd.m.Y',
        locale: Russian,
    };

    getValue() {
        return this.value ? this.value.split(' — ', 2) : false;
    }

    reset() {
        this.value = '';
    }
}

export default FilterPeriod;
</script>
