import http from '../../js/http';

const URI_REQUESTS_LIST = '/requests/list';
const URI_REQUESTS_INFO = '/requests/info';

let fetcherStarted = false;

export default {
    BOOT({ commit, dispatch }, app) {
        commit('BOOT', app);

        if (!fetcherStarted) {
            fetcherStarted = true;
            dispatch('REQUESTS_OBSERVER', +new Date());
            dispatch('REQUESTS_INFO_OBSERVER', +new Date());
        }
    },

    REQUESTS_OBSERVER({ state, commit, dispatch, rootState: { account } }, lastTimeUpdate = +new Date()) {
        const requestIds = state.activeRequests.map((request) => request.id);

        if (account.authorized && requestIds.length > 0) {
            const data = {
                requestIds,
                lastTimeUpdate,
            };

            http.post(URI_REQUESTS_LIST, data).then(({ data: requests }) =>
                commit('UPDATE_REQUESTS_CONTEXT', requests),
            );
        }

        setTimeout(dispatch, 28000, 'REQUESTS_OBSERVER', lastTimeUpdate);
    },

    REQUESTS_INFO_OBSERVER({ commit, dispatch, rootState: { account } }, lastTimeUpdate = +new Date()) {
        if (account.authorized) {
            http.post(URI_REQUESTS_INFO).then(({ data: info }) => commit('UPDATE_MENU_INFO', info));
        }

        setTimeout(dispatch, 14000, 'REQUESTS_INFO_OBSERVER', lastTimeUpdate);
    },
};
