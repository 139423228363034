<template>
    <PagePaginateControl
        :page="value"
        :pages="pages"
        :views="views"
        @next="next"
        @prev="prev"
        @set="set"
        v-if="pages > 1"
    >
        <template v-for="(name, key) in views">
            <button
                :class="{
                    button_green: value == name,
                    button_orange: value != name,
                }"
                :key="key"
                @click="set(name)"
                class="button pagination__item pagination__item_control"
            >
                {{ name }}
            </button>
        </template>
    </PagePaginateControl>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';

import PagePaginateControl from './PagePaginateControl';

@Component({
    components: {
        PagePaginateControl,
    },
})
class PagePaginate extends Vue {
    @Prop({ type: Number, default: 0 })
    value;
    @Prop({ type: Number, default: 0 })
    total;
    @Prop({ type: Number, default: 10 })
    count;
    @Prop({ type: Number, default: 2 })
    tiles;

    first = 1;

    @Emit('input')
    @Emit('change')
    set(value) {
        return value;
    }

    @Emit('next')
    next() {
        if (this.value >= this.pages) return;

        return this.set(this.value + 1);
    }

    @Emit('prev')
    prev() {
        if (this.value <= 1) return;

        return this.set(this.value - 1);
    }

    get pages() {
        return Math.ceil(this.total / this.count);
    }

    get views() {
        let offset = 0;
        const views = [];
        for (let page = this.value - this.tiles; page <= this.value + this.tiles + offset; page++) {
            if (page < 1) offset++;
            if (page > 1 && page < this.pages) views.push(page);
        }

        return views;
    }
}

export default PagePaginate;
</script>
