export default class ModalNames {
    static ERROR = 'ERROR';

    static REQUEST_PAUSE = 'REQUEST_PAUSE';
    static REQUEST_CHANGE_SERVICE = 'REQUEST_CHANGE_SERVICE';
    static REQUEST_CHANGE_EXECUTOR = 'REQUEST_CHANGE_EXECUTOR';
    static REQUEST_SEND_TO_BITRIX24 = 'REQUEST_SEND_TO_BITRIX24';

    static ADMIN_PANEL_USER_CARD = 'ADMIN_PANEL_USER_CARD';
    static ADMIN_PANEL_CITY_CARD = 'ADMIN_PANEL_CITY_CARD';
    static ADMIN_PANEL_SERVICE_CARD = 'ADMIN_PANEL_SERVICE_CARD';
    static ADMIN_PANEL_CATEGORY_CARD = 'ADMIN_PANEL_CATEGORY_CARD';
    static ADMIN_PANEL_SECURITY_GROUP_CARD = 'ADMIN_PANEL_SECURITY_GROUP_CARD';
}
