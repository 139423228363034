<script>
import Vue from 'vue';
import validate from 'validate.js';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';

import VFormFieldWrapper from './VFormFieldWrapper';

@Component({
    components: {
        VFormFieldWrapper,
    },
})
class VForm extends Vue {
    @Prop(Object) model;
    @Prop(Object) rules;
    @Prop({ type: Object, default: undefined }) validateOptions;

    result = [];

    submit() {
        this.beforeSubmit(this.model);

        this.result = validate(this.model, this.rules, this.validateOptions);

        if (this.result) this.error(this.result);
        else this.success(this.model);

        this.afterSubmit(this.result);
    }

    @Emit('beforeSubmit')
    beforeSubmit(model) {
        return model;
    }

    @Emit('beforeSubmit')
    afterSubmit(result) {
        return result;
    }

    @Emit('error')
    error(result) {
        return result;
    }

    @Emit('submit')
    success(model) {
        return model;
    }

    wrap(slots) {
        return slots.map((slot) => {
            if (!slot.data.attrs || !slot.data.attrs.name || !this.result || !this.model)
                return this.$createElement('VFormFieldWrapper', {}, [slot]);

            const name = slot.data.attrs.name;
            const data = {
                props: {
                    name: name,
                    model: this.model[name],
                    result: this.result[name],
                },
            };
            return this.$createElement('VFormFieldWrapper', data, [slot]);
        });
    }

    render(h) {
        const data = {
            on: {
                submit: (event) => {
                    event.preventDefault();

                    this.submit(event);
                },
            },
            props: {
                model: this.model,
            },
        };
        const slots = this.wrap(this.$slots.default);

        return h('form', data, slots);
    }
}

export default VForm;
</script>
