<template>
    <div class="v-form__field">
        <label class="v-form__field-label" v-show="label">{{ label }}</label>
        <slot></slot>
    </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
class VFormField extends Vue {
    @Prop(String) label;
}

export default VFormField;
</script>
<style lang="scss">
.v-form__field {
    &-label {
        display: inline-block;
        padding-bottom: 3px;
    }
}
</style>
