<template>
    <div class="ie">
        <div class="ie__warning">
            <div class="ie__warning_bold">Ваш браузер устарел!</div>

            <span>
                Service Desk может работать медленно и с ошибками. Для быстрой и стабильной работы рекомендуем
                установить последнюю версию одного из следующих браузеров:
            </span>
        </div>

        <div class="good-browsers">
            <a class="good-browser" href="https://www.google.com/intl/ru/chrome/">
                <div class="good-browser__background good-browser__background_chrome"></div>
                Chrome
            </a>

            <a class="good-browser" href="https://yandex.ru/firefox/?from=wizard___one">
                <div class="good-browser__background good-browser__background_firefox"></div>
                Firefox
            </a>

            <a class="good-browser" href="https://yandex.ru/opera/">
                <div class="good-browser__background good-browser__background_opera"></div>
                Opera
            </a>

            <a class="good-browser" href="https://browser.yandex.ru/?from=wizard__yabro_one_&banerid=0500000000">
                <div class="good-browser__background good-browser__background_yandex"></div>
                Yandex
            </a>
        </div>
    </div>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';

import ModuleContentCenter from '@/views/modules/default/ModuleContentCenter';

@Component({
    components: {
        ModuleContentCenter,
    },
})
class IE extends Vue {}

export default IE;
</script>
<style lang="scss">
.ie {
    width: 780px;
    height: auto;
    padding-top: 100px;
    margin: auto;
    font: 400 1rem Roboto;

    &__warning {
        text-align: center;
        padding-bottom: 20px;

        &_bold {
            font-weight: bold;
            color: #ff0000;
            text-transform: uppercase;
        }
    }
}

.good-browsers {
    text-align: center;
}

.good-browser {
    display: inline-block;
    margin: 0 10px;
    padding: 20px 20px 15px;
    text-align: center;
    border-radius: 2px;

    &__background {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
        background-image: url('../../../images/browsers.png');

        &_chrome {
            background-position: 0 -167px;
        }

        &_firefox {
            background-position: 0 -83px;
        }

        &_opera {
            background-position: 0 -251px;
        }

        &_yandex {
            background-position: 0 -336px;
        }
    }
    &:hover {
        background-color: rgba(44, 44, 44, 0.12);
    }
}
</style>
