<template>
    <VModalWrapper :title="title" v-on="listeners" v-bind="attributes">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form class="active-form" @submit.prevent="handleSubmit(submit)">
                <ValidationProvider class="active-form__field" name="title" rules="max:64" v-slot="{ errors }">
                    <label class="active-form__label active-form__label_required" for="category-title">Название</label>

                    <VInput
                        id="category-title"
                        name="title"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="category.title"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider
                    class="active-form__field"
                    name="subtitle"
                    rules="required|max:128"
                    v-slot="{ errors }"
                >
                    <label class="active-form__label active-form__label_required" for="category-subtitle"
                        >Описание</label
                    >

                    <VInput
                        id="category-title"
                        name="subtitle"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="category.subtitle"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <div class="active-form__actions">
                    <VButton type="button" class="active-form__action button_green-light" @click="hide">
                        Отмена
                    </VButton>

                    <VButton type="submit" class="active-form__action button_green"> Сохранить </VButton>
                </div>
            </form>
        </ValidationObserver>
    </VModalWrapper>
</template>
<script>
// Components
import VInput from '../components/VInput/VInput.vue';
import VButton from '../components/VButton/VButton.vue';
import VSelect from '../components/VSelect/VSelect.vue';
import VModalWrapper from '../components/VModalWrapper/VModalWrapper.vue';
import VContenteditable from '../components/VContenteditable/VContenteditable.vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalNames from '../../js/enums/ModalNames';
import ModalMixin from '../../js/vue/mixins/ModalMixin';

@Component({
    components: {
        VInput,
        VButton,
        VSelect,
        VModalWrapper,
        VContenteditable,

        ValidationObserver,
        ValidationProvider,
    },
})
class VAdminCategoryCard extends ModalMixin {
    @Prop({ type: String, default: ModalNames.ADMIN_PANEL_CATEGORY_CARD })
    name;
    @Prop({ type: String, default: 'Карточка категории' })
    defaultTitle;

    category = null;

    submit() {
        this.resolve(this.category);

        this.hide();
    }

    beforeOpen(event) {
        this.category = event?.params?.category;
    }

    beforeClose() {
        this.category = null;
    }
}

export default VAdminCategoryCard;
</script>
