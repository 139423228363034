export const generate = () => {
    return Math.random().toString(36).substr(2, 9);
};

export const generateId = () => {
    return '_id-' + generate();
};

export const generateName = () => {
    return '_name-' + generate();
};

String.prototype.formatUnicorn = function () {
    'use strict';
    var str = this.toString();
    if (arguments.length) {
        var t = typeof arguments[0];
        var key;
        var args = 'string' === t || 'number' === t ? Array.prototype.slice.call(arguments) : arguments[0];

        for (key in args) {
            str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), args[key]);
        }
    }

    return str;
};
