<template>
    <div class="details">
        <div class="details__user-card" @click.stop>
            <UserCard :account="account" />
        </div>

        <ul class="details__rows">
            <li class="details__row" v-if="$_isAdmin">
                <RouterLink :to="{ name: 'admin-panel.users' }" class="details__link">
                    <SettingsSvg class="details__icon"></SettingsSvg>

                    <span class="details__text">Админ панель</span>
                </RouterLink>
            </li>

            <li class="details__row" @click="logout">
                <div class="details__link">
                    <ExitSvg class="details__icon"></ExitSvg>

                    <span class="details__text">Выйти</span>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
// Svg
import ExitSvg from '@/images/exit.svg';
import SettingsSvg from '@/images/settings.svg';

// Components
import UserCard from '@/views/components/VUser/UserCard.vue';

// Other
import { Vue, Component, Prop } from 'vue-property-decorator';
import oidc from '../../../vue-oidc-client';

@Component({
    components: {
        ExitSvg,
        SettingsSvg,

        UserCard,
    },
})
class UserDerails extends Vue {
    @Prop(Object) account;

    logout() {
        oidc.signOut();
    }
}

export default UserDerails;
</script>
<style lang="scss">
.details {
    z-index: 100;
    position: absolute;
    z-index: 1010;
    top: 14px;
    right: 0;
    width: 320px;
    border-radius: 2px;
    background-color: var(--color-white);
    box-shadow: 0px 2px 15px 3px rgba(44, 44, 44, 0.12);
    color: var(--text-black);

    &__user-card {
        display: block;
        padding: 8px 7px;
        color: initial;
        cursor: pointer;
        text-decoration: none;
    }

    &__rows {
        margin: 0;
        padding: 8px 0;
        list-style: none;
        border-top: 1px solid rgba(44, 44, 44, 0.12);
    }

    &__link {
        display: flex;
        color: var(--text-black);
        padding: 8px 15px;
        align-items: center;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            background-color: rgba(44, 44, 44, 0.12);
        }
    }

    &__icon {
        width: 20px;
        height: 20px;
    }

    &__text {
        padding: 0 12px;
        font: 400 0.9rem Roboto;
    }
}
</style>
