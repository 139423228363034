import './VGrid.scss';
import Vue from 'vue';

const name = 'grid-layer';

export default Vue.extend({
    name,

    functional: true,

    props: {
        id: {
            type: String,
            default: '',
        },
        tag: {
            type: String,
            default: 'div',
        },
        value: {
            type: Boolean,
            default: false,
        },
        zIndex: {
            type: [String, Number],
            default: 1000,
        },
    },

    render(h, { props, data, children }) {
        data.staticClass = `${name} ${data.staticClass || ''}`.trim();

        const { attrs } = data;
        if (attrs) {
            data.attrs = {};

            for (const key in attrs) {
                if (key === 'slot') {
                    continue;
                }

                const value = attrs[key];

                if (key.startsWith('data-') || key === 'role') {
                    data.attrs[key] = value;
                    continue;
                }

                data.staticClass += ` ${key}`;
            }
        }

        if (props.id) {
            data.domProps = data.domProps || {};
            data.domProps.id = props.id;
        }

        data.style = {
            'height': props.value ? '100%' : 0,
            'opacity': props.value ? 1 : 0,
            'z-index': props.zIndex,
        };

        return h(props.tag, data, children);
    },
});
