<template>
    <span @click.prevent="onClick" class="toggle-button-link">
        <slot></slot>
    </span>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';

@Component
class ToggleButtonLink extends Vue {
    @Prop({ type: Boolean, default: false }) value;

    @Emit('input')
    onClick() {
        return !this.value;
    }
}

export default ToggleButtonLink;
</script>
<style lang="scss">
.toggle-button-link {
    color: #26b168;
    cursor: pointer;
    font: 400 0.9rem Roboto;
    text-decoration: underline;
    user-select: none;
}
</style>
