<template>
    <div class="admin-page-table">
        <div class="admin-page-table__head" v-if="options && options.columns">
            <template v-for="(column, index) in options.columns">
                <div class="admin-page-table__cell" :style="{ width: getColumnSize(column) }" :key="index">
                    {{ column.label }}
                </div>
            </template>

            <template v-if="defaultColumnSize && (options.editable || options.deletable)">
                <div class="admin-page-table__cell" :style="{ width: getColumnSize() }">Действия</div>
            </template>
        </div>

        <VueCustomScrollbar class="admin-page-table__body" @ps-y-reach-end="$emit('next-page')">
            <template v-for="(row, rowIndex) in rows">
                <div
                    class="admin-page-table__row"
                    :class="{ 'admin-page-table__row_last': rowIndex + 1 == rows.length }"
                    :key="rowIndex"
                    @click="$emit('click', row)"
                >
                    <template v-for="(column, columnIndex) in options.columns">
                        <div
                            class="admin-page-table__cell"
                            :style="{ width: getColumnSize(column) }"
                            :key="columnIndex"
                        >
                            {{ column.display(row) }}
                        </div>
                    </template>

                    <template v-if="options && defaultColumnSize && (options.editable || options.deletable)">
                        <div
                            class="admin-page-table__cell admin-page-table__cell_action"
                            :style="{ width: getColumnSize() }"
                        >
                            <button
                                class="admin-page-table__action"
                                v-if="isEditable(options.editable, row)"
                                @click.stop="$emit('edit', row)"
                            >
                                <EditSvg></EditSvg>
                            </button>

                            <button
                                class="admin-page-table__action admin-page-table__action_delete"
                                v-if="isDeletable(options.deletable, row)"
                                @click.stop="$emit('delete', row)"
                            >
                                <DeleteSvg></DeleteSvg>
                            </button>
                        </div>
                    </template>
                </div>
            </template>

            <PagePaginate
                class="admin-page-table__paginator"
                :count="limit"
                :total="total"
                :value="page"
                @input="(value) => $emit('page', value)"
            ></PagePaginate>
        </VueCustomScrollbar>
    </div>
</template>
<script>
// Svg
import EditSvg from '@/images/edit.svg';
import DeleteSvg from '@/images/delete.svg';

// Components
import VButton from '../../../components/VButton/VButton.vue';
import PagePaginate from '../../../components/PagePaginate/PagePaginate.vue';
import VueCustomScrollbar from 'vue-custom-scrollbar';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    components: {
        EditSvg,
        DeleteSvg,

        VButton,
        PagePaginate,
        VueCustomScrollbar,
    },
})
class ContentTableDefault extends Vue {
    @Prop({ type: Array, default: () => [] })
    rows;

    @Prop({ type: Number, default: 1 })
    page;

    @Prop({ type: Number, default: 1 })
    total;

    @Prop({ type: Number, default: 15 })
    limit;

    @Prop({ type: Object, default: () => {} })
    options;

    isCallable(value) {
        return typeof value === 'function';
    }

    isEditable(editable, value) {
        if (this.isCallable(editable)) {
            return editable(value);
        }

        return !!editable;
    }

    isDeletable(deletable, value) {
        if (this.isCallable(deletable)) {
            return deletable(value);
        }

        return !!deletable;
    }

    getColumnSize(column) {
        var size = column?.size ?? this.defaultColumnSize;

        return size + '%';
    }

    get defaultColumnSize() {
        let columns = this.options?.columns?.length ?? 0;
        let occupied = 0;

        if (this.options.editable || this.options.deletable) {
            columns = columns + 1;
        }

        if (this.options && Array.isArray(this.options.columns)) {
            occupied = this.options.columns.reduce((sum, column) => {
                if (column.size) {
                    columns = columns - 1;
                    sum = sum + column.size;
                }

                return sum;
            }, 0);
        }

        if (occupied > 100) {
            return 0;
        }

        return (100 - occupied) / columns;
    }
}

export default ContentTableDefault;
</script>
<style lang="scss">
:root {
    --admin-page-table-head-color: #808080;
    --admin-page-table-border-color: #e5e4e5;
    --admin-page-table-background-color: var(--color-white);
    --admin-page-table-row-hover-color: #f0f0f0;
}

.admin-page-table {
    height: 100%;
    overflow: hidden;

    &__row,
    &__head {
        display: flex;
        margin: 0 36px 0 36px;
        padding: 30px 24px;
        border-bottom: 1px solid var(--admin-page-table-border-color);
        background-color: var(--admin-page-table-background-color);
    }

    &__row {
        cursor: pointer;

        &_last {
            border-bottom-left-radius: 18px;
            border-bottom-right-radius: 18px;
            border: none;
        }

        &:hover {
            background-color: var(--admin-page-table-row-hover-color);
        }
    }

    &__cell {
        display: flex;
        width: 100%;
        align-items: center;
    }

    &__head {
        border-top-left-radius: 18px;
        border-top-right-radius: 18px;
        color: var(--admin-page-table-head-color);
    }

    &__body {
        height: calc(100% - 82px) !important;
        overflow: hidden;
        padding-bottom: 24px;

        & .ps__thumb-y {
            right: 20px;

            &::after {
                bottom: 66px;
            }
        }
    }

    &__action {
        width: 36px;
        height: 36px;
        margin: 0 12px 0 0;
        padding: 8px;

        &:hover {
            color: var(--color-green);
        }

        &:last-child {
            margin: 0;
        }

        &_delete {
            &:hover {
                color: var(--color-red);
            }
        }
    }

    &__paginator {
        width: auto;
        margin: 8px 21px 0 21px;
        justify-content: center;
    }
}
</style>
