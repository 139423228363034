<template>
    <transition name="animation-fadedown">
        <div class="image-popup" v-show="image" @click.prevent="hideImagePopup">
            <div class="image-popup__wrapper">
                <div class="image-popup__close">
                    <WindowCloseIcon class="image-popup__close-icon" fill-color="#fff" :size="22"></WindowCloseIcon>
                </div>

                <img :src="image" class="image-popup__image" @click.prevent.stop />
            </div>
        </div>
    </transition>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import WindowCloseIcon from 'vue-material-design-icons/WindowClose';

@Component({
    components: {
        WindowCloseIcon,
    },
})
class VImagePopup extends Vue {
    image = '';

    showImagePopup(image) {
        this.image = image;
    }

    hideImagePopup() {
        this.image = '';
    }

    created() {
        this.$bus.on('ImagePopup:Show', this.showImagePopup);
    }

    beforeDestroy() {
        this.$bus.off('ImagePopup:Show', this.showImagePopup);
    }
}

export default VImagePopup;
</script>
<style lang="scss">
.image-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.5);

    &__wrapper {
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    &__close {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        margin: 5px;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
    }

    &__close-icon {
        width: 22px;
        height: 22px;
        cursor: pointer;
    }

    &__image {
        height: auto;
        max-width: 75%;
        max-height: 75%;
        border-radius: 2px;
    }
}
</style>
