import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import { isVuexBooted, bootVuex } from './store';
import oidc from './vue-oidc-client';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes,
});

oidc.useRouter(router);

router.beforeEach((to, _from, next) => {
    const isSignInPage = to.name === 'signinwin-sso';
    if (isSignInPage) {
        return next();
    }

    const isAuthenticated = oidc.isAuthenticated;
    if (!isVuexBooted && isAuthenticated) {
        bootVuex().then(() => {
            next();
        });

        return;
    }

    next();
});

export const redirect = (path) => {
    router.push(path);
};

export default router;
