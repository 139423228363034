<template>
    <div class="field-settings-menu">
        <div class="field-settings-menu__list" role="list">
            <template v-for="(field, i) in fields">
                <div class="field-settings-menu__item" :key="i" @click="switchRender(field)">
                    <div class="field-settings-menu__title">
                        {{ field.title }}
                    </div>
                    <div class="field-settings-menu__switch">
                        <VSwitchButton color="#00a55a" v-model="field.render"></VSwitchButton>
                    </div>
                </div>
            </template>
        </div>
        <div class="field-settings-menu__save" v-if="showSaveSubmit">
            <VButton class="button_small button_light" @click="onClickSave">Сохранить</VButton>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';

import _clone from 'lodash/clone';

import VButton from '../../../components/VButton/VButton.vue';
import VSwitchButton from '../../../components/VCheckbox/VSwitchButton.vue';

@Component({
    components: { VSwitchButton, VButton },
})
class FieldSettingsMenu extends Vue {
    /** @type {Array} **/
    @Prop({ type: Array, default: () => [] }) renderFields;

    fields = {};

    @Emit('save')
    onClickSave() {
        return this.fields;
    }

    switchRender(field) {
        field.render = !field.render;
    }

    get showSaveSubmit() {
        return this.fields.some((field) => {
            const renderField = this.renderFields.find((renderField) => field.name === renderField.name);

            return renderField.render != field.render;
        });
    }

    created() {
        this.fields = this.renderFields.map((x) => _clone(x));
    }
}

export default FieldSettingsMenu;
</script>
<style lang="scss">
.field-settings-menu {
    width: 280px;
    cursor: pointer;
    padding: 5px 0;
    border-radius: 4px;
    background-color: var(--color-white);
    border: 1px solid #a8acae;

    &__item {
        display: flex;
        width: 100%;
        padding: 4px 20px;
        justify-content: space-between;

        &:hover {
            background-color: rgba(0, 0, 0, 0.045);
        }
    }

    &__save {
        display: flex;
        padding: 2px 15px;
        justify-content: flex-end;
    }

    &__switch {
        display: flex;
        align-items: center;
    }
}
</style>
