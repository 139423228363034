<template>
    <div class="file-viewer__model file-viewer__model_image" v-if="isImageFile">
        <div class="file-viewer__model-wrap">
            <div class="file-viewer__model-wrap_image" @click="showImagePopup">
                <img
                    class="file-viewer__model-image"
                    :class="{
                        'file-viewer__model-image_mini': isMini,
                    }"
                    :src="imageSrc"
                    alt
                />
            </div>
        </div>
        <slot></slot>
    </div>

    <a :href="downloadHref" @click="download" class="file-viewer__model" target="_blank" v-else>
        <div class="file-viewer__model-wrap">
            <div class="file-viewer__model-icon">
                <span class="file-viewer__model-ext">{{ expansion(model.name) }}</span>
            </div>
            <span class="file-viewer__model-name">{{ model.name }}</span>
        </div>
        <slot></slot>
    </a>
</template>
<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

let serverURL = process.env.VUE_APP_SERVER_URL;

if (!serverURL) {
    serverURL = '';
}

if (serverURL.endsWith('/')) {
    serverURL = serverURL.slice(0, -1);
}

const downloadBaseUrl = serverURL + '/files';

@Component
class FileViewerModel extends Vue {
    @Prop({ type: Object, default: () => {}, required: true }) model;

    download(event) {
        if (this.model.id) return;

        event.preventDefault();
    }

    showImagePopup() {
        this.$bus.emit('ImagePopup:Show', this.imageSrc);
    }

    expansion(name) {
        // Splits the filename with a dot.
        const names = name.split('.');
        // Gets the file expansion.
        const expansion = names[names.length - 1];

        // Takes the first 3 symbols of the file expansion.
        return expansion.substring(0, 3);
    }

    get isMini() {
        return !!this.model.dataURL;
    }

    get imageSrc() {
        return this.model.dataURL ? this.model.dataURL : `${downloadBaseUrl}/${this.model.publicId}`;
    }

    get isImageFile() {
        return !!this.model.type.match(/^image\/*/);
    }

    get downloadHref() {
        if (!this.model || this.model.publicId < 1) return '';

        return `${downloadBaseUrl}/${this.model.publicId}`;
    }
}

export default FileViewerModel;
</script>
<style lang="scss">
.file-viewer__model {
    position: relative;
    color: initial;
    text-decoration: none;
    cursor: pointer;

    &-image {
        width: auto;
        max-height: 200px;

        &_mini {
            max-height: 90px;
        }
    }

    &-wrap {
        display: flex;
        padding: 5px 0;
        margin: 0 10px;
        align-items: center;

        &_image {
            display: block;
            cursor: pointer;
        }
    }

    &-name {
        padding-left: 10px;
        font: 400 0.9rem Roboto;
    }

    &-ext {
        color: #f1905b;
        overflow: hidden;
        font: 400 0.8rem Roboto;
        text-transform: uppercase;
    }

    &-icon {
        display: flex;
        width: 36px;
        height: 36px;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: rgba(44, 44, 44, 0.12);
    }

    &:hover {
        border-radius: 4px;
        background-color: rgba(44, 44, 44, 0.12);
    }

    &_image {
        cursor: default;

        &:hover {
            background-color: transparent;
        }
    }
}
</style>
