<template>
    <form :class="$style['search']" @submit.prevent="submit">
        <InputLightSearch :placeholder="placeholder" :value="value" @input="input"></InputLightSearch>

        <VButton class="button_green" :class="$style['search__button']">Поиск</VButton>
    </form>
</template>

<script>
import VButton from '@/views/components/VButton/VButton.vue';

import InputLightSearch from '@/views/components/input/components/InputLightSearch';

export default {
    props: {
        value: {
            type: String,

            default: '',
        },

        placeholder: {
            type: String,

            default: '',
        },
    },

    components: {
        VButton,

        InputLightSearch,
    },

    methods: {
        input(value) {
            this.$emit('input', value);
        },

        submit() {
            this.$emit('submit', this.value);
        },

        clear() {
            this.$emit('input', '');
        },
    },

    created() {
        this.$bus.on('tools:clear', this.clear);

        this.$bus.on('search:clear', this.clear);
    },

    beforeDestroy() {
        this.$bus.off('tools:clear', this.clear);

        this.$bus.off('search:clear', this.clear);
    },
};
</script>

<style lang="scss" module>
.search {
    display: flex;

    &__button {
        padding: 5px 15px;
        margin-left: 10px;
        border-radius: 4px;
    }
}
</style>
