export const defaultMessages = {
    UNKNOWN_ERROR: 'Неизвестная ошибка',
    SOMETHING_WENT_WRONG: 'Что-то пошло не так',
};

export const clientMessages = {};

export const serverErrors = {};

export const serverMessages = {};

export default defaultMessages;
