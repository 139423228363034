import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component()
class ModalMixin extends Vue {
    @Prop({ type: String, default: '' }) name;
    @Prop({ type: String, default: '' }) defaultTitle;

    title = '';
    promise = null;

    hide() {
        this.$modal.hide(this.name);
    }

    reject(result = {}) {
        if (this.promise && this.promise.reject && !this.promise.completed) {
            this.promise.reject(result);
        }
    }

    resolve(result = {}) {
        if (this.promise && this.promise.resolve && !this.promise.completed) {
            this.promise.resolve(result);
        }
    }

    beforeOpen() {}
    beforeClose() {}

    _beforeOpen($event) {
        if ($event && $event.params) {
            this.title = $event.params.title ? $event.params.title : this.defaultTitle;
            this.promise = $event.params.promise;
        }

        this.beforeOpen($event);
    }

    _beforeClose($event) {
        this.$_title = this.defaultTitle;
        this.promise = null;

        this.reject();

        this.beforeClose($event);
    }

    get listeners() {
        return {
            ...this.$listeners,
            'before-open': this._beforeOpen,
            'before-close': this._beforeClose,
        };
    }

    get attributes() {
        const name = this.name;
        const height = this.$attrs.height ? this.$attrs.height : 'auto';
        const adaptive = this.$attrs.adaptive !== false;
        const scrollable = this.$attrs.scrollable !== false;

        return {
            ...this.$attrs,
            name,
            height,
            adaptive,
            scrollable,
        };
    }

    created() {
        this.title = this.defaultTitle;
    }
}

export default ModalMixin;
