import Vue from 'vue';

import 'core-js';
import './js/bootstrap';

import App from './App';
import store from './store';
import router from './router';
import oidc from './vue-oidc-client';

Vue.config.productionTip = false;

oidc.startup().then((ok) => {
    if (ok) {
        new Vue({
            store,
            router,
            render: (h) => h(App),
        }).$mount('#app');

        oidc.events.addUserSignedOut(() => {
            oidc.signIn();
        });
    } else {
        new Vue({
            render: (h) => h('h1', 'Something went wrong...'),
        }).$mount('#app');
    }
});
