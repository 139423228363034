<template>
    <div class="request-user">
        <div class="request-user__avatar" v-if="photo(user)" v-show="isPhotoLoaded">
            <img :src="photo(user)" class="request-user__avatar-thumbnail" @load="onPhotoLoad" />
        </div>

        <div class="request-user__avatar request-user__avatar_icon" v-if="!photo(user) || !isPhotoLoaded">
            <AccountIcon :size="24"></AccountIcon>
        </div>
        <span class="request-user__display-name">{{ displayName }}</span>
    </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import AccountIcon from 'vue-material-design-icons/Account';

@Component({
    components: {
        AccountIcon,
    },
})
class RequestUser extends Vue {
    @Prop({ type: Boolean, default: false })
    reduce;
    @Prop({ type: Object, default: () => ({}) })
    user;
    @Prop({ type: Function, default: (user) => user?.displayName })
    name;
    @Prop({ type: Function, default: (user) => user?.photo })
    photo;

    isPhotoLoaded = false;

    reduceName(name) {
        const _name = name.split(' ');

        if (_name.length != 3) return name;

        const lastName = _name[2];
        const firstName = _name[1];
        const surnameName = _name[0];

        return `${surnameName} ${firstName[0]}.${lastName[0]}.`;
    }

    onPhotoLoad() {
        this.isPhotoLoaded = true;
    }

    get displayName() {
        return this.reduce ? this.reduceName(this.name(this.user)) : this.name(this.user);
    }
}

export default RequestUser;
</script>
<style lang="scss">
.request-user {
    display: flex;

    &__avatar {
        width: 24px;
        height: 24px;
        margin: 0 8px;
        border-radius: 50%;

        &-thumbnail {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            object-fit: cover;
        }

        &_icon {
            font-size: 0.8rem;
            background: #f1f1f1;

            & > span {
                display: inline-block;
                width: 24px;
                height: 24px;
                text-align: center;
            }
        }
    }

    &__display-name {
        display: flex;
        font-size: 0.875rem;
        line-height: 1.25rem;
        align-items: center;
    }
}
</style>
